import { ISbRichtext, RichtextResolver } from 'storyblok-js-client';

const richTextResolver = new RichtextResolver();

export const isRichTextEmpty = (data?: ISbRichtext) => !data || (data?.content?.[0].type !== 'blok' && !data?.content?.[0].content);

export function renderRichText(data: ISbRichtext): string {
  const localResolver = richTextResolver;

  if (isRichTextEmpty(data)) {
    return '';
  }

  return localResolver.render(data);
}
