














import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'BKInput',
  props: {
    value: { type: [String, Number], default: '' },
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    name: { type: String, default: '' },
    type: { type: String, default: 'text' },
    max: { type: [Number, null], default: null },
    min: { type: [Number, null], default: null },
  },
});
