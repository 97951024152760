









  import {
defineComponent, onMounted,
} from '@nuxtjs/composition-api';

enum EnumIFrameAlignment {
  LEFT = 'left',
  ALIGNED_WITH_TEXT = 'alignWithText',
}

export default defineComponent({
  name: 'IFrame',
  components: {},
  props: {
    alignment: { type: String, default: EnumIFrameAlignment.LEFT },
    content: { type: String, default: '' },
  },
  setup() {
    onMounted(() => {
      const iFrame = document.querySelector('iframe');
      const iFrameWidth = iFrame.clientWidth;

      iFrame.style.maxWidth = `${iFrameWidth}px`;
    });

    return {
      EnumIFrameAlignment,
    };
  },
});
