















  import {
defineComponent,
} from '@nuxtjs/composition-api';
import BKAccordeon from '~/components/BK/BKAccordeon.vue';

export default defineComponent({
  name: 'FAQs',
  components: {
    BKAccordeon,
  },
  props: {
    headline: { type: String, default: '' },
    items: { type: Array, default: () => [] },
  },
  setup() {},
});
