import { gql } from '~/utils/graphql';

export const customerAccountConfirmationMutation = gql(`
  mutation customerAccountConfirmation($input: AccountConfirmationInput!) {
    customerAccountConfirmation(input: $input) {
      status
      error {
          field
          message
      }
    }
  }
`);
