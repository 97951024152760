export enum Routes {
  PRODUCT = 'produkt',
  CATEGORY = 'kategorie',
  SEARCH = 'suche',
  EXPERT_ADVICE = 'fachgeschaeft/terminbuchung-kaufberatung',
  PEDALE = 'kategorie/zubehor/zum-rad/pedale',
  FINANCING = 'fachgeschaeft/finanzierung',
}

export const bikeNameQueryParameter = 'bike';

export const ROOT_BIKE_CATEGORY = 'alle-rader';
export const ALLE_RADER_URL = `/${Routes.CATEGORY}/${ROOT_BIKE_CATEGORY}`;

export const searchTermQueryParameter = 'term';
