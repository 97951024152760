






















import { defineComponent } from '@nuxtjs/composition-api';
import { optimizeImage } from '~/helpers/image';
import { Image } from './models/Image.model';

export default defineComponent({
  name: 'CmsImage',
  props: {
    image: { type: Object, default: () => {} },
    caption: { type: String, default: '' },
  },
  setup(props) {
    const imageProp : Image = props.image as Image;

    const optimizedImage: string = imageProp.filename;

    return {
      imageProp,
      optimizedImage,
    };
  },
  mounted() {
    this.optimizedImage = optimizeImage(this.imageProp, '700x400');
  },
});
