






































































import {
defineComponent,
ref,
useContext,
watch,
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default defineComponent({
  components: {
    SfInput,
    ValidationObserver,
    ValidationProvider,
  },
  setup(props, { emit }) {
    const { app: { i18n } } = useContext();
    const form = ref({
      firstName: '',
      lastName: '',
      emailField: '',
      phone: '',
    });
    const observer = ref(null);

    watch(() => form, (updatedForm) => {
      emit('updated-form', updatedForm.value);
    }, { immediate: true, deep: true });

    return {
      i18n,
      form,
      observer,
    };
  },
  mounted() {
    this.observer.value = this.$refs.observer;
  },
  methods: {
    clearFields() {
      this.form = {
        firstName: '',
        lastName: '',
        emailField: '',
        phone: '',
      };
    },
  },
});
