import {
  FooterStory, NavigationStory, Story, StoryblokHeaderAndFooter,
} from '../useStoryblok/useStoryblok';
import { Page } from './usePage';

export function usePage(story: Story): Page {
  // navigation and menuItems are page relations and available in the 'rels' property
  let navigation = null;
  const nav = story?.rels?.find((item) => item.content?.component === 'Navigation');
  if (nav) {
    const menuItems = nav.content?.menuItems as string[];
    if (menuItems) {
      const menuItemsFull = menuItems.map((menuItem) => story?.rels?.find((item) => item.content?.component === 'MenuItem' && item.uuid === menuItem));
      navigation = {
        fontColor: nav.content.fontColor,
        backgroundColor: nav.content.backgroundColor,
        menuItems: [...menuItemsFull],
      };
    }
  }

  const newsletterText = story?.rels?.find((item) => item.content?.component === 'Newsletter')?.content.text;
  const footerContent = story?.rels?.find((item) => item.content?.component === 'Footer')?.content;
  const topBarContent = story?.rels?.find((item) => item.content?.component === 'TopBar')?.content;

  return {
    navigation,
    topBarContent,
    newsletterText,
    footerContent,
  };
}

export function useNavigation(header: NavigationStory, footer: FooterStory): StoryblokHeaderAndFooter {
  // navigation and menuItems are page relations and available in the 'rels' property
  let navigation = null;
  const isNavigation = header.story.content?.component === 'Navigation';
  if (isNavigation) {
    const menuItems = header.story.content?.menuItems;
    if (menuItems) {
      const menuItemsFull = menuItems.map((menuItem) => header.rels?.find((item) => item.content?.component === 'MenuItem' && item.uuid === menuItem));
      navigation = {
        fontColor: header.story.content.fontColor,
        backgroundColor: header.story.content.backgroundColor,
        menuItems: [...menuItemsFull],
      };
    }
  }

  const footerContent = footer.story.content?.component === 'Footer' ? footer.story.content : null;
  return {
    navigation,
    footerContent,
  };
}
