

















































































import { defineComponent, ref, Ref } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import BKLink from '~/components/BK/BKLink.vue';
import MenuTeaser from './MenuTeaser.vue';
import { MenuItem } from './Navigation';

interface AccordeonItemState {
  index: number,
  isOpen: boolean
}

export default defineComponent({
  name: 'MobileHeaderNavigationMenu',
  components: { MenuTeaser, SfIcon, BKLink },
  props: {
    menuItems: { type: Array, default: () => [] },
  },
  setup(props) {
    const openStates: Ref<AccordeonItemState[]> = ref([]);
    const { toggleMobileMenu } = useUiState();

    const getLinkFromItem = (item: MenuItem) => (item.content.link?.length > 0 ? item.content.link[0] : null);

    const items = props.menuItems as MenuItem[];
    items.forEach((_item, index: number) => openStates.value.push({ index, isOpen: false }));

    const closeMobileMenu = () => {
      toggleMobileMenu();
    };

    return {
      items,
      openStates,
      closeMobileMenu,
      getLinkFromItem,
    };
  },
  methods: {
    openAccordeon(index: number): void {
      const { isOpen } = this.openStates.find((state: any) => state.index === index);
      this.openStates.find((state: any) => state.index === index).isOpen = !isOpen;
    },
    getIconByState(isOpen: boolean) {
      return isOpen ? 'chevron_up' : 'chevron_down';
    },
    isOpen(index: number): boolean {
      return this.openStates[index].isOpen;
    },
  },
});

