var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BKHeader',{staticClass:"bk-header--has-mobile-search",class:{
      'header-on-top': _vm.isSearchOpen,
      'bg-gray-50': _vm.backgroundColor === _vm.BackgroundColor.LightGray,
      'bg-transparent header-on-top relative': _vm.backgroundColor === _vm.BackgroundColor.Transparent
    },style:({ color: _vm.fontColor }),scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('HeaderLogo',{class:{ '!order-2': _vm.isOverflowed }})]},proxy:true},{key:"navigation",fn:function(){return [(_vm.menuItems)?_c('HeaderNavigation',{class:{ '!order-1': _vm.isOverflowed },attrs:{"cms-menu-items":_vm.menuItems},on:{"resized-window":function (overflowed) { return _vm.isOverflowed = overflowed; }}}):_vm._e()]},proxy:true},{key:"header-icons",fn:function(ref){
    var activeIcon = ref.activeIcon;
return [_c('div',{staticClass:"bk-header__icons"},[(!(!_vm.isBreakpointXL && _vm.isSearchOpen))?_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-search'),expression:"'app-header-search'"}],staticClass:"sf-button--pure bk-header__action",attrs:{"data-testid":"searchIcon","aria-label":"Search"},on:{"click":function () { return _vm.isSearchOpen = !_vm.isSearchOpen; }}},[_c('SfIcon',{class:[
              'bk-header__icon',
              activeIcon === 'search' ? 'is-active' : '',
              _vm.fontColor === _vm.FontColor.White ? 'bk-header__icon--white' : 'bk-header__icon--black'
            ],attrs:{"icon":_vm.searchIcon,"label":_vm.$t('Search')}})],1):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-account'),expression:"'app-header-account'"}],staticClass:"sf-button--pure bk-header__action",attrs:{"data-testid":"accountIcon","aria-label":"Account"},on:{"click":_vm.handleAccountClick}},[_c('SfIcon',{class:[
              'bk-header__icon',
              activeIcon === 'account' ? 'is-active' : '',
              _vm.fontColor === _vm.FontColor.White ? 'bk-header__icon--white' : 'bk-header__icon--black'
            ],attrs:{"icon":_vm.accountIcon,"label":_vm.$t('Account')}})],1),_vm._v(" "),_c('div',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-cart'),expression:"'app-header-cart'"}],key:_vm.cartTotalItems,staticClass:"sf-button--pure bk-header__action sf-button",attrs:{"aria-label":"Toggle cart sidebar"},on:{"click":_vm.toggleCartSidebar}},[_c('SfIcon',{staticClass:"bk-header__icon",class:[
              'bk-header__icon',
              activeIcon === 'cart' ? 'is-active' : '',
              _vm.fontColor === _vm.FontColor.White ? 'bk-header__icon--white' : 'bk-header__icon--black'
            ],attrs:{"icon":_vm.cartIcon,"label":_vm.$t('Cart')}}),_vm._v(" "),(_vm.cartTotalItems)?_c('SfBadge',{staticClass:"sf-badge--number cart-badge"},[_vm._v("\n            "+_vm._s(_vm.cartTotalItems)+"\n          ")]):_vm._e()],1)],1)]}},{key:"search",fn:function(){return [_c('SearchBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchOpen),expression:"isSearchOpen"}],ref:"searchBar",staticClass:"bk-header__search",attrs:{"font-color":_vm.fontColor,"is-search-open":_vm.isSearchOpen},on:{"set-is-open":function($event){return _vm.toggleSearchBar($event)},"set-search":_vm.searchProducts,"clear-search":_vm.setCursorActive}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }