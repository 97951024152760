enum RendererTypesEnum {
  RADIO = 'RadioType',
  CHECKBOX = 'CheckboxType',
  SWATCH_COLOR = 'SwatchColorType',
  YES_NO = 'YesNoType',
  SLIDER = 'Slider',
  DEFAULT = 'CheckboxType',
  TREE = 'Tree',
}

export default RendererTypesEnum;
