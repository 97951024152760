import { gql } from './utils';

export const setCustomAttributeMutation = gql(`
    mutation setCustomUserAttribute($input: SetCustomUserAttributeInput!) {
        setCustomUserAttribute(input: $input) {
            bk_show_matching_bikes
        }
    }
`);

export type SetCustomUserAttributeInput = {
    bk_show_matching_bikes?: boolean;
};

export type SetShowMatchingBikesOutput = {
  data: {
    bk_show_matching_bikes: boolean;
  } | null;
  errors: { message: string }[];
};
