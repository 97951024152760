










  import { defineComponent } from '@nuxtjs/composition-api';
import RichText from './helpers/RichText.vue';

  export default defineComponent({
    name: 'Claim',
    components: { RichText },
    props: {
      text: { type: Object, default: () => {} },
      variant: { type: String, default: 'dark' },
      hasSpecialPaddings: { type: Boolean, default: false },
    },
  });
