














import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'Badges',
  components: {
    SfImage,
  },
  props: {
    badges: { type: Array, default: () => [] },
    width: { type: String, default: '' },
    height: { type: String, default: '' },
    path: { type: String, required: true },
  },
  setup() {
    return {
      addBasePath,
    };
  },
});
