import {
  Ref, ref,
  useAsync,
  useContext, useFetch,
} from '@nuxtjs/composition-api';
import { AxiosRequestConfig } from 'axios';
import { useNavigation, usePage } from '../usePage';
import {
  FooterStory, NavigationStory,
  Story, StoryblokData,
  StoryblokHeaderAndFooter,
} from './useStoryblok';

const NAVIGATION_ID = 'global/mainmenu-light';
const FOOTER_ID = 'global/footer';
const HEADER_RELATIONS = 'menuItems';

const STORYBLOK_ASSET_CDN_URL = 'https://a.storyblok.com';

const OLD_SHOP_SLUGS = [
  'alle-rader',
  'sortiment/fahrraeder-e-bikes',
  'bikes',
];

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

// we need to manually replace the asset cdn url in the storyblok response
// to replace it with our custom CDN url
const replaceStoryblokCdnUrl = (data: any, storyblokCdnUrl: string) => {
  const jsonData = JSON.stringify(data, null, 2);
  const updatedJsonData = jsonData.replace(new RegExp(STORYBLOK_ASSET_CDN_URL, 'g'), storyblokCdnUrl);
  return JSON.parse(updatedJsonData);
};

export function useUrl(storyId: string, relations?: string): string {
  const { $config } = useContext();

  const { sbUrl, sbContentDeliveryToken, storyblokEnvironment } = $config;
  const version = storyblokEnvironment === 'production' ? 'published' : 'published&version=draft';

  let url = `${sbUrl}/stories/${storyId}?version=${version}&token=${sbContentDeliveryToken}`;

  if (relations) {
    url = `${url}&resolve_relations=${relations}`;
  }

  return url;
}

async function getHeader(menuId: string = NAVIGATION_ID): Promise<NavigationStory> {
  const url = useUrl(menuId, HEADER_RELATIONS);
  const { $config, $axios } = useContext();

  const { storyblokCdnEnabled, storyblokCdnUrl } = $config;
  try {
    const response = await $axios.get(url);
    const responseWithUpdatedAssetCdn = storyblokCdnEnabled
      ? replaceStoryblokCdnUrl(response.data, storyblokCdnUrl as string)
      : response.data;
    const navigationData = responseWithUpdatedAssetCdn as NavigationStory;
    return navigationData;
  } catch (error) {
    console.error(`🔥 Could not get navigation [${menuId}] from ${url}. Following error occurred:`, error);
    return null;
  }
}

async function getFooter(footerId: string = FOOTER_ID): Promise<FooterStory> {
  const url = useUrl(footerId);
  const { $config, $axios } = useContext();

  const { storyblokCdnEnabled, storyblokCdnUrl } = $config;
  try {
    const response = await $axios.get(url);
    const responseWithUpdatedAssetCdn = storyblokCdnEnabled
      ? replaceStoryblokCdnUrl(response.data, storyblokCdnUrl as string)
      : response.data;
    const footerData = responseWithUpdatedAssetCdn as FooterStory;
    return footerData;
  } catch (error) {
    console.error(`🔥 Could not get footer [${footerId}] from ${url}. Following error occurred:`, error);
    return null;
  }
}

/*
    This composable is needed as soon as the route does NOT match the requested cms page
    e.g. /c/alle-fahrraeder/e-bikes --> needs to retrieve the category cms page
*/
export function useStoryblokAsync(
  id: string,
  relations?: string,
): Ref<StoryblokData> {
  // fallback key for '/' route
  const storyId = id ?? 'home';
  const { error: nuxtError, $config, $axios } = useContext();

  const { storyblokCdnEnabled, storyblokCdnUrl } = $config;

  // there is a weird issue on the 'impressum' page with a 2nd fetch to the storyblok function
  // where the key is set to a 'null' string - in that case we just ignore the request.
  if (!storyId || storyId === 'null') {
    return ref({ story: null });
  }

  // these requests are coming from the old shop - all the urls are invalid and the fallback is the cms page.
  // we want to ignore these requests to save api calls to storyblok.
  // potential redirects are already handled on nuxt level before.
  if (OLD_SHOP_SLUGS.some((slug) => storyId.startsWith(slug))) {
    nuxtError({ statusCode: 404 });
    return ref({ story: null });
  }

  const url = useUrl(storyId, relations);

  const storyblokData = useAsync(async () => {
    try {
      const conf: AxiosRequestConfig = { validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404 };
      const response = await $axios.get(url, conf);
      if (!response.cached) {
        console.log(`❌ Retrieved uncached response for ${url}`);
      }
      if (response.status === 404) {
        console.error(`🔥 Could not get story [${storyId}] from ${url} (Not found). Cached: ${response.cached}`);
        nuxtError({ statusCode: 404 });
        return { story: null };
      }
      const responseWithUpdatedAssetCdn = storyblokCdnEnabled
        ? replaceStoryblokCdnUrl(response.data, storyblokCdnUrl as string)
        : response.data;
      const story = responseWithUpdatedAssetCdn as Story;
      return {
        story,
        ...usePage(story),
      };
    } catch (error) {
      console.error(`🔥 Could not get story [${storyId}] from ${url} (Server error). Following error occurred:`, error);
      nuxtError({ statusCode: 404 });
      return { story: null };
    }
  }, storyId);

  return storyblokData;
}

export function useStoryblokStartWith(
  startsWith: string,
  excludingFields: string = '',
  sortBy: string = 'published_at',
  sortDirection: SortDirection = SortDirection.DESC,
) {
  const stories = ref(null);
  const { $config, $axios } = useContext();

  const {
    sbUrl, sbContentDeliveryToken, enviroment, storyblokCdnEnabled, storyblokCdnUrl,
  } = $config;
  const version = enviroment === 'production' ? 'published' : 'draft&version=published';

  const url = `${sbUrl}/stories?excluding_fields=${excludingFields}&starts_with=${startsWith}&version=${version}&token=${sbContentDeliveryToken}&sort_by=${sortBy}:${sortDirection}`;

  useFetch(async () => {
    try {
      const response = await $axios.get(url);
      const responseWithUpdatedAssetCdn = storyblokCdnEnabled
        ? replaceStoryblokCdnUrl(response.data.stories, storyblokCdnUrl as string)
        : response.data.stories;
      stories.value = responseWithUpdatedAssetCdn;
    } catch (error) {
      console.error(`🔥 Could not get stories that start with '${startsWith}' from ${url}. Following error occurred:`, error);
    }
  });

  return { stories };
}

export function useStoryblokNavigationAndFooter(): Ref<StoryblokHeaderAndFooter> {
  const storyblokData = useAsync(async () => {
    try {
      const [header, footer] = await Promise.all([getHeader(), getFooter()]);
      return useNavigation(header, footer);
    } catch (error) {
      console.error('🔥 Error retrieving navigation and footer. Following error occurred:', error);
      return {
        navigation: null,
        footerContent: null,
      };
    }
  });

  return storyblokData;
}
