






























import { SfCheckbox } from '@storefront-ui/vue';
import { ValidationProvider } from 'vee-validate';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    SfCheckbox,
    ValidationProvider,
  },
  props: {
    rules: { type: [String, Object] as any, default: () => '' },
    className: { type: String, default: () => '' },
    label: { type: String, required: true },
    value: { type: Boolean, required: true },
    helpText: { type: String, default: () => null },
  },
  data() {
    return {
      isChecked: false,
    };
  },
});
