import { gql } from './utils';

export const addProductsToCart = gql(`
    mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
      addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
        cart {
          items {
            id
            product {
              name
              sku
            }
            quantity
            seat_position {
              height
              belly_button_height
              weight
              seat_position
            }
          }
        }
        user_errors {
          code
          message
        }
      }
    }
`);
