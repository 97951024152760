



















import { defineComponent } from '@nuxtjs/composition-api';
import BKButton from '~/components/BK/BKButton.vue';
import { Button, EnumButtonVariant } from './models/Button.model';
import { Link } from './models/Link.model';

export default defineComponent({
  name: 'SingleButton',
  components: { BKButton },
  props: {
    button: { type: Array, default: () => [] },
  },
  setup(props) {
    const buttonProperties: Button = props.button[0] as Button;

    return {
      buttonProperties,
      EnumButtonVariant,
    };
  },
  methods: {
    isMailTo(link: Link) {
      return link.email;
    },
  },

});
