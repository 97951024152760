const fragmentPriceRangeFields = `
  fragment PriceRangeFields on PriceRange {
    maximum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
  }
`;

export default `
  query getProductPriceAndCustomBySku(
    $search: String,
    $filter: ProductAttributeFilterInput,
    $configurations: [ID!]
  ) {
    products(search: $search, filter: $filter) {
      items {
        categories {
          id
          url_key
        }
        attribute_set_id
        quantity
        price_range {
          ...PriceRangeFields
        }
        custom {
          show_as_available_soon
          show_as_new
          exhibited_in_shop
          frametool_code
          model_year
          area_of_use
          geometry
          geometry_dimensions
          manufacturer_name
          country_of_manufacture
          manufacturer_weight
          frame_shape
          material
          groupset
          frame
          fork
          fork_travel
          damper
          manufacturer_suspension
          drive_type
          transmission
          manufacturer_circuit
          groupset_type
          rear_derailleur
          derailleur
          shifter
          crankset
          cassette
          brakes_type
          brakes
          manufacturer_brakes
          wheelset_material
          wheel_size
          wheels
          tires
          integrated_cockpit
          handlebars
          saddle
          seatpost
          stem
          manufacturer_engine_name
          battery
          drive_unit
          drive_unit_individual
          range_extender_optional
          display
          display_individual
          remote
          remote_individual
          charger
          charger_individual
          body_height_kids
          luggage_carrier
          delivery_without_pedals
          kohl_description
          kohl_note
          kohl_short_description
          manufacturer_ean
          item_ean
          item_number
          kohl_description_headline
          kohl_note_headline
          kohl_short_description_headline
          mudguards
          related_products_link
          country_of_manufacture
          manufacturer_goods
          size_goods
        }
        ... on ConfigurableProduct {
          variants {
            product {
              uid
              id
              name
              sku
              stock_status
              quantity
            }
            attributes {
              code
              label
              value_index
            }
          }
          price_range {
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }

          configurable_product_options_selection(configurableOptionValueUids: $configurations) {
            options_available_for_selection {
              attribute_code
              option_value_uids
            }
            media_gallery {
              disabled
              label
              position
              url
            }
            variant {
              uid
              sku
              name
              price_range {
                ...PriceRangeFields
              }
            }
          }
        }

        ... on BundleProduct {
          items {
            position
            required
            sku
            title
            type
            uid
            options {
              can_change_quantity
              is_default
              position
              uid
              quantity
              product {
                uid
                sku
                name
                price_range {
                   ...PriceRangeFields
                }
              }
            }
          }
        }

        ... on GroupedProduct {
          items {
            position
            qty
            product {
              uid
              sku
              name
              stock_status
              only_x_left_in_stock
              price_range {
                maximum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                minimum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              thumbnail {
                url
                position
                disabled
                label
              }
            }
          }
        }

      }
    }
  }
  ${fragmentPriceRangeFields}
`;
