


























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'BKHeader',
  props: {
    cartIcon: {
      type: [String, Boolean, Array],
      default: 'empty_cart',
    },
    accountIcon: {
      type: [String, Boolean, Array],
      default: 'profile',
    },
    activeIcon: {
      type: String,
      default: '',
      validator(value: string) {
        return ['', 'account', 'wishlist', 'cart'].includes(value);
      },
    },
    cartItemsQty: {
      type: Number,
      default: 0,
    },
  },
});
