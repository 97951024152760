export type Expand<T extends Object = Object> = {
  [key in keyof T]: Expand
};

export interface IBaseParams {
  page?: number
  limit?: number
}

export interface IBaseModel {
  _uid: string
}

export class BaseModel extends Object implements IBaseModel {
  _uid: string;

  constructor(model: IBaseModel) {
    super();

    // eslint-disable-next-line no-underscore-dangle
    this._uid = model._uid;
  }
}
