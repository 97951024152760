
































import { defineComponent, useRoute, useRouter } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import IconSprite from '~/components/General/IconSprite.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LazyHydrate,
    IconSprite,
    TrustedShopsBadge: () => import(/* webpackPrefetch: true */ '~/components/TrustedShopsBadge/TrustedShopsBadge.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    BikeFinderModal: () => import(/* webpackPrefetch: true */ '~/components/BikeFinder/BikeFinder.vue'),
    CookiesModal: () => import(/* webpackPrefetch: true */ '~/components/CookieConsentModal.vue'),
    FramesizeModal: () => import(/* webpackPrefetch: true */ '~/modules/catalog/framesize/components/FramesizeModal.vue'),
    FinancingModal: () => import(/* webpackPrefetch: true */ '~/components/Financing/FinancingModal.vue'),
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isFramesizeModalOpen,
      toggleFramesizeModal,
      toggleBikeFinderModal,
      isBikeFinderModalOpen,
      isFinancingModalOpen,
      toggleFinancingModal,
    } = useUiState();

    const toggleBikeFinder = () => {
      if (isBikeFinderModalOpen) router.replace({ query: {} });
      toggleBikeFinderModal();
    };

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isFramesizeModalOpen,
      toggleFramesizeModal,
      route,
      isBikeFinderModalOpen,
      toggleBikeFinderModal,
      isFinancingModalOpen,
      toggleFinancingModal,
      toggleBikeFinder,
    };
  },
  head() {
    // we need to make sure the canonicals do not contain %2F in the url
    const seoMetaHeaders = this.$nuxtI18nHead({ addSeoAttributes: true });
    const updatedLink = seoMetaHeaders.link.map((link) => {
        const updatedHref = link.href?.replaceAll('%2F', '/');
        return {
          ...link,
          href: updatedHref,
        };
      });
    return {
      ...seoMetaHeaders,
      link: [...updatedLink, { rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
    };
  },
});
