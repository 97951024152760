





































import type { NuxtError } from '@nuxt/types';
import type { PropType } from '@nuxtjs/composition-api';
import {
defineComponent, Ref,
} from '@nuxtjs/composition-api';
import AppHeader from '~/components/AppHeader.vue';
import BKButton from '~/components/BK/BKButton.vue';
import AppFooter from '~/components/Footer/AppFooter.vue';
import { useStoryblokNavigationAndFooter } from '~/composables/useStoryblok';
import { StoryblokHeaderAndFooter } from '~/composables/useStoryblok/useStoryblok';
import { ALLE_RADER_URL } from '~/helpers/routes';

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
    BKButton,
},
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup() {
    const storyblokData : Ref<StoryblokHeaderAndFooter> = useStoryblokNavigationAndFooter();

    return {
      storyblokData,
      ALLE_RADER_URL,
    };
  },
});
