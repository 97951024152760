



































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { arrowRightIcon } from '../cms/helpers/customIcons';
import BKButton from './BK/BKButton.vue';
import BKInput from './BK/BKInput.vue';

export default defineComponent({
  name: 'Newsletter',
  components: {
    BKInput,
    BKButton,
  },
  props: {
    text: { type: String, default: '' },
  },
  setup() {
    const userEmail = ref('');

    return {
      userEmail,
      arrowRightIcon,
    };
  },
});
