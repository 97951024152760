



















import { defineComponent } from '@nuxtjs/composition-api';
import { Image } from '../models/Image.model';
import Card from './components/Card.vue';

interface CardItem {
  _uid: string,
  image: Image,
  headline: string,
  text: Object,
  link: Array<Object>,
  onlyLinkImage?: boolean,
}

export default defineComponent({
  name: 'Cards',
  components: {
    Card,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  setup(props) {
    const cardItems: CardItem[] = props.items as CardItem[];
    return {
      cardItems,
    };
  },

});
