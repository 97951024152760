






























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { Link } from '~/cms/models/Link.model';
import BKLink from '~/components/BK/BKLink.vue';
import { optimizeImage } from '~/helpers/image';
import RichText from '../../helpers/RichText.vue';
import { openCmsLink } from '../../helpers/urls';
import { Image } from '../../models/Image.model';

  export default defineComponent({
    name: 'Card',
    components: {
      RichText,
      BKLink,
    },
    props: {
      image: { type: Object, default: () => {} },
      headline: { type: String, default: '' },
      text: { type: Object, default: () => {} },
      link: { type: Object, default: () => {} },
      onlyLinkImage: { type: Boolean, default: false },
    },
    setup(props) {
      const cardLink: Link = props.link?.link as Link;
      const cardImage: Image = props.image.url as Image;
      const hasText: boolean = !!props.text.content[0].content?.length;
      const imageProp: Image = props.image.url as Image;
      const optimizedImage: string = imageProp.filename;
      const onlyLinkImageProp: boolean = props.onlyLinkImage ?? false;
      const showCardLink = computed(() => cardLink && !onlyLinkImageProp);

      return {
        optimizedImage,
        imageProp,
        cardImage,
        cardLink,
        hasText,
        showCardLink,
        onlyLinkImageProp,
      };
    },
    mounted() {
      this.optimizedImage = optimizeImage(this.imageProp, '500x300');
    },
    methods: {
      openLink() {
        if (!this.cardLink || !this.onlyLinkImageProp) {
          return;
        }

        openCmsLink(this.cardLink, this.$router);
      },
  },
  });
