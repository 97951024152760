

























import { SfTextarea } from '@storefront-ui/vue';
import { ValidationProvider } from 'vee-validate';
import { defineComponent } from '@nuxtjs/composition-api';
import { getFormFieldLabel } from './utils';

export default defineComponent({
  components: {
    SfTextarea,
    ValidationProvider,
  },
  props: {
    rules: { type: [String, Object] as any, default: () => '' },
    className: { type: String, default: () => '' },
    label: { type: String, required: true },
    value: { type: String, required: true },
    helpText: { type: String, default: () => null },
  },
  methods: {
    getLabel() {
      return getFormFieldLabel(this.label, this.rules);
    },
  },
});
