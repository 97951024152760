import { gql } from '~/utils/graphql';

export const submitContactFormMutation = gql(`
  mutation SubmitContactForm($input: ContactFormInput!) {
    submitContactForm(input: $input) {
      errors {
        field
        message
      }
    }
  }
`);

export type ContactEmailOutput = {
  errors: { field: string, message: string }[];
};

export type ContactEmailInput = {
  first_name: string
  last_name: string;
  email: string;
  phone_number: string;
  reference: string;
  comment: string;
  contact_me_request: string;
  recaptcha?: string;
};
