export default `
    mutation applyGiftCardToCart($cartId: String = "", $giftCardCode: String = "") {
        removeMwGiftCardFromCart(
            input: {
                cart_id: $cartId
                gift_card_code: $giftCardCode
            }
        ) {
            cart {
                applied_mw_gift_cards {
                    code
                    remaining {
                        value
                        currency_code
                        label
                    }
                    applied {
                        value
                        currency_code
                        label
                    }
                }
            }
        }
    }
`;
