




















































































































































import { defineComponent, ref, Ref } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import { CmsLink } from '~/cms/models/Link.model';
import BKLink from '~/components/BK/BKLink.vue';
import { addBasePath } from '~/helpers/addBasePath';
import Badges from './components/Badges.vue';

interface IColumn {
  _uid: string,
  headline: string,
  links: CmsLink[]
}

export default defineComponent({
  name: 'AppFooter',
  components: {
    SfImage,
    Badges,
    BKLink,
  },
  props: {
    content: { type: Object, required: true },
  },
  setup(props) {
    const firstRowContent: Ref<IColumn[]> = ref(props.content.firstRow);
    const secondRowContent: Ref<IColumn[]> = ref(props.content.secondRow);
    const openingHoursWeek: Ref<string> = ref(props.content.openingHoursWeek);
    const openingHoursWeekend: Ref<string> = ref(props.content.openingHoursWeekend);
    const specialHours: Ref<string> = ref(props.content.specialHours);

    const paymentMethodsBadges: string[] = ['visa', 'mastercard', 'paypal'];
    const shippingMethodsBadges: string[] = ['go', 'post'];
    const qualityBadges: string[] = ['activate2022', 'verpackungslizenzierung'];
    const socials: { icon: string, link: string }[] = [
      {
        icon: 'facebook',
        link: 'https://www.facebook.com/people/Bernhard-Kohl/100064023036247/?locale=de_DE',
      },
      {
        icon: 'youtube',
        link: 'https://www.youtube.com/channel/UCdhi3YJLLvix67TyGxSY2SA',
      },
      {
        icon: 'instagram',
        link: 'https://www.instagram.com/bernhardkohlshop/?hl=de',
      },
    ];
    const currentyear: number = new Date().getFullYear();

    return {
      firstRowContent,
      secondRowContent,
      paymentMethodsBadges,
      shippingMethodsBadges,
      addBasePath,
      qualityBadges,
      socials,
      currentyear,
      openingHoursWeekend,
      openingHoursWeek,
      specialHours,
    };
  },
  methods: {
    openLink(link: string): void {
      window.open(link, '_blank');
    },
  },
});
