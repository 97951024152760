import { Image } from '~/cms/models/Image.model';

/**
 * Resizes the given image to the specified crop size and appends focus point filters if they exist.
 * @param image - The image to optimize.
 * @param cropSize - The size to crop the image to. Defaults to '1200x0' while maintaining original aspect ratio.
 * @returns The optimized image source URL.
 */
export function optimizeImage(image: Image, cropSize: string = '1200x0') {
  if (!image.filename) {
    return null;
  }

  let imageSource = `${image.filename}/m/${cropSize}`;

  // if image.focus exist then append the focus point filters to the image
  if (image.focus) {
    imageSource += `/filters:focal(${image.focus})`;
  }

  return imageSource;
}
