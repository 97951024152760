

















































































import {
defineComponent,
ref, useContext,
} from '@nuxtjs/composition-api';
import {
SfCheckbox,
SfTextarea,
} from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  Select as SelectControl,
} from '~/components/Forms';

import BKButton from '~/components/BK/BKButton.vue';
import { useApi, useUiNotification } from '~/composables';
import { submitContactFormMutation } from '~/graphql/submitContactForm.gql';
import PersonalDetailsForm from './components/PersonalDetailsForm.vue';

interface IPersonalDetails {
  firstName?: string,
  lastName?: string,
  emailField?: string,
  phone?: string
}

export default defineComponent({
  components: {
    SfTextarea,
    PersonalDetailsForm,
    BKButton,
    SfCheckbox,
    ValidationObserver,
    ValidationProvider,
    SelectControl,
  },
  setup() {
    const { app: { i18n } } = useContext();
    const { mutate } = useApi();
    // @ts-ignore
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));
    const valid = false;
    const submitted = false;
    const message = '';
    const regarding = '';
    const personalDetailsForm: IPersonalDetails = {};
    const callRequirement = false;
    const isRecaptchaSuccessful = ref(false);

    const isSuccessModalVisible = false;

    const dropdownOptions = [
      {
        label: i18n.t('General Request'),
        value: i18n.t('General Request'),
      },
      {
        label: i18n.t('Buying a bike'),
        value: i18n.t('Buying a bike'),
      },
      {
        label: i18n.t('Service'),
        value: i18n.t('Service'),
      },
      {
        label: i18n.t('Service bike leasing'),
        value: i18n.t('Service bike leasing'),
      },
    ];

    const { send: sendNotification } = useUiNotification();

    const onRecaptchaSuccess = (token: string) => {
      if (token) {
        isRecaptchaSuccessful.value = true;
      }
    };

    return {
      valid,
      submitted,
      message,
      dropdownOptions,
      regarding,
      callRequirement,
      isSuccessModalVisible,
      isRecaptchaEnabled,
      personalDetailsForm,
      $recaptcha,
      formSent: false,
      sendNotification,
      i18n,
      mutate,
      isLoading: ref(false),
      error: false,
      onRecaptchaSuccess,
      isRecaptchaSuccessful,
    };
  },
  methods: {
    validate() {
      const form = this.personalDetailsForm;

      if (
        (form.firstName && form.firstName.length < 40)
        && (form.lastName && form.lastName.length < 40)
        && form.emailField
        && this.regarding
        && (this.message && this.message.length > 10 && this.message.length < 800)
      ) {
        if (form.phone) {
          this.valid = form.phone.length < 40;
        } else if (!this.phone) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      } else {
        this.valid = false;
      }
    },
    personalDetailsFormUpdated(form: IPersonalDetails) {
      this.personalDetailsForm = form;
      this.validate();
    },
    async submitForm(reset: () => void) {
      this.validate();

      if (this.isRecaptchaEnabled) {
        this.$recaptcha.init();
      }

      if (!this.valid) return;
      try {
        this.isLoading = true;

        const form = {
          first_name: this.personalDetailsForm.firstName,
          last_name: this.personalDetailsForm.lastName,
          email: this.personalDetailsForm.emailField,
          phone_number: this.personalDetailsForm.phone,
          reference: this.regarding,
          comment: this.message,
          contact_me_request: this.callRequirement ? String(this.i18n.t('Yes')) : String(this.i18n.t('No')),
          recaptcha: '',
        };

        if (this.isRecaptchaEnabled) {
          const recaptchaToken = await this.$recaptcha.getResponse();
          form.recaptcha = recaptchaToken;

          if (!form.recaptcha) return;
        }

        const result = await this.mutate(submitContactFormMutation, { input: form });

        if (result.errors?.length > 0) {
          this.error = true;
        }

        if (this.error) {
          this.sendNotification({
            id: Symbol('product_removed'),
            message: `${this.i18n.t('Oops! Something went wrong')} \n\n ${this.i18n.t('An error has occurred. Please try again or contact us directly via email.')}`,
            type: 'danger',
            icon: 'check',
          });
        } else {
          this.sendNotification({
            id: Symbol('product_removed'),
            message: `${this.i18n.t('Thank you for your message!')} \n\n ${this.i18n.t('Message sent succesfully')}`,
            type: 'success',
            icon: 'check',
          });
        }
        this.submitted = true;

        this.reset();
        reset();

        if (this.isRecaptchaEnabled) {
          this.$recaptcha.reset();
        }

        // Clear fields on the external form
        (this.$refs.personalDetailsForm as any).clearFields();

        this.formSent = true;
      } catch {
        this.formSent = false;
      }

      this.isLoading = false;
    },
    reset() {
      this.personalDetailsForm = {};
      this.message = '';
      this.callRequirement = false;
      this.regarding = '';
    },
  },
});
