export default `
  query getProductsSkus($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 12, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        uid
        sku
      }
    }
  }
`;
