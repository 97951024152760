import { useApi } from '~/composables';
import {
  BikeMatchInput, BikeMatchOutput, BikeMatchResponse, calculateMatchingBikesMutation,
} from '~/graphql/calculateMatchingBikes';
import { SetCustomUserAttributeInput, SetShowMatchingBikesOutput, setCustomAttributeMutation } from '~/graphql/setCustomUserAttribute';
import { Logger } from '~/helpers/logger';

import { FramesizeFormFields } from './types';

export function useFrametoolApi() {
  const { mutate } = useApi();

  const calculateFramesize = async (framesizeForm: FramesizeFormFields, categoryId?: number | null): Promise<BikeMatchOutput | null> => {
    const {
      gender, height, weight, stepLength,
    } = framesizeForm;

    // API expects 'M', 'F' or other for gender
    const input: BikeMatchInput = {
      category_id: categoryId,
      gender: gender === '1' ? 'M' : (gender === '2' ? 'F' : 'NA'),
      height: +height,
      weight: +weight,
      step_length: +stepLength,
    };

    const { data: graphqlData } = await mutate<{ 'calculateMatchingBikes': BikeMatchResponse }>(calculateMatchingBikesMutation, { input });

    if (!graphqlData) {
      Logger.error('Could not retrieve matching bikes');
      return null;
    }
    const { data, errors } = graphqlData.calculateMatchingBikes;
    if (errors.length > 0) {
      Logger.error(errors);
      return null;
    }

    return data;
  };

  const setShowMatchingBikes = async (showMatchingBikes: boolean): Promise<Boolean | null> => {
    const input: SetCustomUserAttributeInput = {
      bk_show_matching_bikes: showMatchingBikes,
    };
    const { data: graphqlData, errors } = await mutate<{ 'setCustomUserAttribute': SetShowMatchingBikesOutput }>(setCustomAttributeMutation, { input });

    if (errors?.length > 0) {
      Logger.error('Could not set show matching bikes attribute');
      return null;
    }

    const { data } = graphqlData.setCustomUserAttribute;

    return !!data?.bk_show_matching_bikes;
  };

  return { calculateFramesize, setShowMatchingBikes };
}
