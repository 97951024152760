var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-zinc-100 sm:grid sm:gap-x-6 max-w-3xl m-auto !px-4 md:!px-6 lg:!px-10",class:{
    'sm:grid-cols-3': _vm.numberOfItems > 2,
    'sm:grid-cols-2': _vm.numberOfItems === 2
  }},_vm._l((_vm.items),function(teaser,index){return _c('Teaser',{key:teaser._uid,staticClass:"w-full",class:{
      'w-6/12': _vm.numberOfItems === 2,
      'w-4/12': _vm.numberOfItems > 2,
      'sm:mt-14': index === 0 && _vm.numberOfItems === 3,
      'sm:mt-28': index >= 2 || index === 0 && _vm.numberOfItems === 2 },attrs:{"number-of-items":_vm.numberOfItems,"item":teaser}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }