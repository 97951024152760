export default `
  query getCustomCustomerAddresses
    {
      customer {
        addresses {
          city
          country_code
          company
          default_billing
          default_shipping
          extension_attributes {
            attribute_code
            value
            __typename
          }
          firstname
          id
          lastname
          postcode
          prefix
          region {
            region
            region_code
            region_id
            __typename
          }
          street
          suffix
          telephone
          vat_id
          __typename
        }
      }
    }
`;
