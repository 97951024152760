






























import { defineComponent } from '@nuxtjs/composition-api';
import { optimizeImage } from '~/helpers/image';
import { Image } from '~/cms/models/Image.model';
import { SfIcon } from '@storefront-ui/vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'BikeFinder',
  components: {
    SfIcon,
  },
  props: {
    caption: { type: String, default: '' },
    headline: { type: String, default: '' },
    image: { type: Object, default: () => {} },
  },
  setup(props) {
    const imageProp : Image = props.image as Image;
    const optimizedImage: string = '';

    const {
      toggleBikeFinderModal,
    } = useUiState();

    return {
      imageProp,
      optimizedImage,
      toggleBikeFinderModal,
    };
  },
  mounted() {
    const selfTeaser = this.$refs.selfTeaser as HTMLElement;

    this.optimizedImage = `url('${optimizeImage(this.imageProp, `${selfTeaser.clientWidth}x${selfTeaser.clientHeight}`)}')`;
  },
});
