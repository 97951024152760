






































import { defineComponent } from '@nuxtjs/composition-api';
import { PropType } from 'vue';
import BikeDeliveryContent from './BikeDeliveryContent.vue';

import { BikeDelivery } from './bikeDelivery.gql';

export default defineComponent({
  name: 'BikeDeliveryResult',
  components: {
    BikeDeliveryContent,
  },
  props: {
    bikeDelivery: { type: Object as PropType<BikeDelivery>, required: true },
  },
  methods: {
    getSanitizedHtml(
      i18nKey: string,
      variables: Record<string, string | number>,
    ) {
      const content = this.$t(i18nKey, variables);
      return this.$dompurify(content as string);
    },
  },
});
