

















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { discount } from '~/helpers/priceHelper';

export default defineComponent({
  name: 'BKPrice',
  props: {
    regularPrice: { type: String, required: true },
    specialPrice: { type: String, default: null },
    showDiscount: { type: Boolean, default: false },
  },
  setup(props) {
    const discountedPercentage = computed(() => discount(props.regularPrice, props.specialPrice));
    return {
      discountedPercentage,
    };
  },
});
