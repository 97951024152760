import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _1d6e0a76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _78e8446c = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _deb8aae2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _2f52fc9a = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1d513f9d = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _6e20a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4b19c3ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _ea4d8d10 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _3f59f33c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _236eec38 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _4be06504 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _4ccbb92c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "" */))
const _04da1e3f = () => interopDefault(import('../pages/ClearCache.vue' /* webpackChunkName: "" */))
const _3c170070 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "" */))
const _ae734422 = () => interopDefault(import('../modules/customer/pages/MyAccount/ConfirmEmail.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _a301d77a,
    name: "checkout",
    children: [{
      path: "benutzerkonto",
      component: _1d6e0a76,
      name: "user-account"
    }, {
      path: "lieferung",
      component: _78e8446c,
      name: "shipping"
    }, {
      path: "rechnung",
      component: _deb8aae2,
      name: "billing"
    }, {
      path: "bezahlung",
      component: _2f52fc9a,
      name: "payment"
    }, {
      path: "danke",
      component: _a5ccad1e,
      name: "thank-you"
    }]
  }, {
    path: "/warenkorb",
    component: _1d513f9d,
    name: "cart"
  }, {
    path: "/benutzer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "mein-profil",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "address-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "address-details/neu",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "address-details/bearbeiten/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "bestelluebersicht",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "bestelluebersicht/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "meine-bewertungen",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }]
  }, {
    path: "/suche",
    component: _731f1fce,
    name: "search"
  }, {
    path: "/clear-cache",
    component: _04da1e3f,
    name: "clear-cache"
  }, {
    path: "/passwort-zuruecksetzen",
    component: _3c170070,
    alias: "/benutzer/konto/passwort-erstellen",
    name: "reset-password"
  }, {
    path: "/",
    component: _740316af,
    name: "home"
  }, {
    path: "/produkt/:url_key",
    component: _731f1fce,
    name: "product"
  }, {
    path: "/kategorie/:slug+",
    component: _731f1fce,
    name: "category"
  }, {
    path: "/bestatigen",
    component: _ae734422,
    alias: "/benutzer/konto/bestatigen",
    name: "customer-email"
  }, {
    path: "/:slug+",
    component: _740316af,
    name: "cms"
  }, {
    path: "/ClearCache",
    component: _04da1e3f,
    name: "ClearCache"
  }, {
    path: "/ResetPassword",
    component: _3c170070,
    name: "ResetPassword"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
