




















import { defineComponent } from '@nuxtjs/composition-api';
import { openCmsLink } from '~/cms/helpers/urls';
import { Image } from '~/cms/models/Image.model';
import { Link } from '~/cms/models/Link.model';
import { optimizeImage } from '~/helpers/image';

export default defineComponent({
  name: 'MenuTeaser',
  props: {
    item: { type: Object, default: () => {} },
  },
  setup(props) {
    const image = props.item.image as Image;
    const link = props.item.link as Link;
    const hasLinkUrl: boolean = Boolean(link.url || link.cached_url || link.id);
    const target: string = link.target ? link.target : '_self';
    const optimizedImage: string = '';

    return {
      image,
      link,
      target,
      hasLinkUrl,
      optimizedImage,
    };
  },
  mounted() {
    const teaserWrapper = this.$refs.teaserWrapper as HTMLElement;

    const dimensions = `${teaserWrapper.clientWidth}x${teaserWrapper.clientHeight}`;
    this.optimizedImage = `url('${optimizeImage(this.image, dimensions)}')`;
  },
  methods: {
    openLink(link: Link): void {
      // Here we want to see if we receive a normal url or an internal one
      if (!this.hasLinkUrl) {
        return;
      }

      openCmsLink(link, this.$router);
    },
  },
});
