/* eslint-disable no-param-reassign */
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import Axios, { AxiosInstance } from 'axios';
import { AxiosCacheInstance, setupCache } from 'axios-cache-interceptor';

declare module '@nuxt/types' {
    interface Context {
      $axios: AxiosCacheInstance | AxiosInstance,
      $axiosClearCache: () => void,
    }
  }

interface MyContext extends Omit<Context, 'ssrContext'> {
    $axios: AxiosCacheInstance | AxiosInstance,
    $axiosClearCache: () => void,
    ssrContext: any
}
export type MyPlugin = (ctx: MyContext, inject: Inject) => Promise<void> | void;
declare module '@nuxt/types' {
  interface Context {
    $axios: AxiosCacheInstance | AxiosInstance,
  }
}

const plugin: MyPlugin = (context, inject) => {
    if (process.server) {
        context.$axios = context.ssrContext.$axios;
        context.$axiosClearCache = context.ssrContext.$axiosClearCache;
        inject('axios', context.ssrContext.$axios);
    } else {
        console.debug('🔌 Setting up axios cache interceptor (browser)');
        const instance = Axios.create();
        const axios = setupCache(instance, { headerInterpreter: (_) => 1000 * 60 * 10 }); // 10 minutes
        context.$axios = axios;
        context.$axiosClearCache = () => console.log('clear cache not available on client side');
        inject('axios', axios);
    }
};

export default plugin;
