export default `
  query getCustomCart($cartId: String = "") {
    cart(cart_id: $cartId) {
      email
      id
      billing_address {
        city
        country {
          code
          label
        }
        firstname
        lastname
        postcode
        company
        region {
          code
          label
        }
        street
        telephone
      }
      shipping_addresses {
        firstname
        lastname
        street
        city
        company
        postcode
        region {
          code
          label
        }
        country {
          code
          label
        }
        telephone
        available_shipping_methods {
          amount {
            currency
            value
          }
          available
          carrier_code
          carrier_title
          error_message
          method_code
          method_title
          price_excl_tax {
            value
            currency
          }
          price_incl_tax {
            value
            currency
          }
        }
        selected_shipping_method {
          amount {
            value
            currency
          }
          carrier_code
          carrier_title
          method_code
          method_title
        }
      }
      items {
        id
        product {
          attribute_set_id
          categories {
            breadcrumbs {
              category_name
              category_url_path
            }
            name
            id
            url_path
            url_suffix
          }
          name
          sku
          quantity
          custom {
            manufacturer_name
          }
          thumbnail {
            disabled
            label
            position
            url
          }
          stock_status
          url_key
          url_rewrites {
            url
          }
        }
        prices {
          row_total {
            value
          }
          row_total_including_tax {
            value
          }
          total_item_discount {
            value
          }
          discounts {
            label
            amount {
              value
            }
          }
        }
        quantity
        seat_position {
          height
          belly_button_height
          weight
          seat_position
        }
        errors {
          code
          message
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            configurable_product_option_value_uid
            option_label
            value_label
          }
          configured_variant {
            sku
            thumbnail {
              url
            }
            quantity
            stock_status
          }
        }
      }
      available_payment_methods {
        code
        title
      }
      selected_payment_method {
        code
        title
      }
      applied_coupons {
        code
      }
      applied_mw_gift_cards {
        code
        remaining {
            value
            currency_code
            label
        }
        applied {
            value
            currency_code
            label
        }
      }
      prices {
        grand_total {
          value
          currency
        }
        subtotal_including_tax {
          value
        }
        discounts {
          label
          amount {
            value
          }
        }
      }
      total_quantity
    }
  }
`;
