































































import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { openCmsLink } from '~/cms/helpers/urls';
import { Link, LinkTypes } from '~/cms/models/Link.model';
import BKButton from '~/components/BK/BKButton.vue';
import { optimizeImage } from '~/helpers/image';
import { isBreakpointLg } from './helpers/breakpoints';
import { arrowRightIcon, calendarIcon } from './helpers/customIcons';
import { EnumDevice } from './helpers/devices';
import { Image } from './models/Image.model';

interface CallToAction {
  link: Link,
  label: string
}

export default defineComponent({
  name: 'HeroHeader',
  components: {
    SfIcon,
    BKButton,
  },
  props: {
    headline: { type: String, default: '' },
    image: { type: Object, default: () => {} },
    caption: { type: String, default: '' },
    link: { type: Object, default: () => {} },
    cta: { type: Array, default: () => [] },
    hasTopBar: { type: Boolean, default: false },
    isHeaderTransparent: { type: Boolean, default: false },
  },
  setup(props) {
    const linkProp : Link = props.link as Link;
    const hasLinkUrl : boolean = Boolean(props.link.url || (props.link.cached_url || props.link.id));
    const chevronIcon : string = hasLinkUrl ? 'chevron_right' : 'chevron_down';
    const callToAction : CallToAction = props.cta[0] as CallToAction;
    const imageProp: Image = props.image as Image;
    const optimizedImage: string = imageProp.filename;

    const expertLink = 'fachgeschaeft/kaufberatung';

    const expertLinkHeader = (): Link => ({
      _uid: expertLink,
      cached_url: `/${expertLink}`,
      linktype: LinkTypes.URL,
      target: '_self',
      url: `/${expertLink}`,
    });

    return {
      imageProp,
      optimizedImage,
      chevronIcon,
      callToAction,
      hasLinkUrl,
      linkProp,
      arrowRightIcon,
      calendarIcon,
      EnumDevice,
      expertLinkHeader,
    };
  },
  mounted() {
    const self = this.$refs.self as HTMLElement;

    this.optimizedImage = `url('${optimizeImage(this.imageProp, `${self.clientWidth}x${self.clientHeight}`)}')`;
  },
  methods: {
    openLink(target: string = EnumDevice.DESKTOP) : void {
      // Only let users to click when they are on mobile width
      const isMobile: boolean = isBreakpointLg(window);

      if (this.hasLinkUrl) {
        if ((isMobile && target === EnumDevice.MOBILE) || (!isMobile && target === EnumDevice.DESKTOP)) {
          openCmsLink(this.linkProp, this.$router);
        }
      } else {
        window.scrollBy(0, 150);
      }
    },
    getHeroHeaderHeightClass() {
      if (this.hasTopBar) {
        return this.isHeaderTransparent ? 'h-[100vh]' : 'h-[87vh]';
      }

      return this.isHeaderTransparent ? 'h-[97vh]' : 'h-[92vh]';
    },
  },
});
