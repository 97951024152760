import { defineStore } from 'pinia';

interface BikeFinderState {
  bikefinderUrl: string | null
}

export const useBikeFinderStore = defineStore('bikefinder', {
  state: (): BikeFinderState => ({
    bikefinderUrl: null,
  }),
});
