











































import {
defineComponent, ref, watch,
} from '@nuxtjs/composition-api';

import { isBreakpointLg, isBreakpointSm } from '~/cms/helpers/breakpoints';
import { useUiState } from '~/composables/useUiState';
import HeaderNavigationItem from './HeaderNavigationItem.vue';
import MenuIcon from './MenuIcon.vue';
import MobileHeaderNavigationMenu from './MobileHeaderNavigationMenu.vue';
import { MenuItem } from './Navigation';

export default defineComponent({
  name: 'HeaderNavigation',
  components: { HeaderNavigationItem, MobileHeaderNavigationMenu, MenuIcon },
  props: {
    cmsMenuItems: { type: Array, required: false, default: () => [] },
  },
  setup(props, { emit }) {
    const { toggleMobileMenu, isMobileMenuOpen } = useUiState();

    const currentMenu = ref<MenuItem | null>(null);

    const setCurrentMenu = (category: MenuItem | null) => {
      currentMenu.value = category;
    };

    const onMouseEnter = (category: MenuItem) => {
      currentMenu.value = category;
    };

    const openMobileMenu = () => {
      toggleMobileMenu();
    };

    const showMenuText: boolean = false;
    const isOverflowed = ref(false);
    const menuItemsFallbackWidth = 1150; // Screen with for when the menu has 9 items (fallback solution)
    const maxWidthWindow = ref(menuItemsFallbackWidth);
    const isScreenWidthGreaterThanFallback = ref(false);

    watch(() => isOverflowed, () => { emit('overflow-status', isOverflowed); }, { immediate: true });

    return {
      setCurrentMenu,
      currentMenu,
      onMouseEnter,
      menuItems: props.cmsMenuItems as MenuItem[],
      openMobileMenu,
      isMobileMenuOpen,
      showMenuText,
      isOverflowed,
      maxWidthWindow,
      menuItemsFallbackWidth,
      isScreenWidthGreaterThanFallback,
    };
  },
  mounted() {
    window.addEventListener('resize', this.resizedWindow);
    this.resizedWindow();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizedWindow);
  },
  methods: {
    calculateShowMenuText() {
      const isTablet: boolean = isBreakpointLg(window);
      const isMobile: boolean = isBreakpointSm(window);
      this.showMenuText = (isTablet || (this.isOverflowed || !this.isScreenWidthGreaterThanFallback)) && !isMobile;

      this.isScreenWidthGreaterThanFallback = window.innerWidth > this.menuItemsFallbackWidth;
    },
    resizedWindow() {
      this.calculateShowMenuText();

      const navigation = this.$refs.navigation as HTMLElement;

      if (navigation) {
        this.isOverflowed = (navigation.scrollWidth) > (navigation.offsetWidth);

        if (!this.isOverflowed) this.maxWidthWindow = window.innerWidth;
      } else this.isOverflowed = false;

      if (this.maxWidthWindow < window.innerWidth) {
        this.isOverflowed = false;
      }

      this.$emit('resized-window', this.isOverflowed);
    },
  },
});
