



















import { defineComponent } from '@nuxtjs/composition-api';
import { openCmsLink } from '~/cms/helpers/urls';
import { Link } from './models/Link.model';

export default defineComponent({
  name: 'ProductPreviewCard',
  props: {
    caption: { type: String, default: '' },
    headline: { type: String, default: '' },
    image: { type: Object, default: () => {} },
    link: { type: Object, default: () => {} },
  },
  setup(props) {
    const linkProp : Link = props.link as Link;
    const hasLinkUrl: boolean = linkProp && Boolean(linkProp.url || (linkProp.cached_url || linkProp.id));

    return {
      linkProp,
      hasLinkUrl,
    };
  },
  methods: {
    openLink() : void {
      // Here we want to see if we receive a normal url or an internal one
      if (!this.hasLinkUrl) {
        return;
      }

      openCmsLink(this.linkProp, this.$router);
    },
  },
});
