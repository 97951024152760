/* eslint-disable max-len */
import {
  Ref,
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { ComposableFunctionArgs, useApi } from '~/composables';
import { Logger } from '~/helpers/logger';
import {
  Cart, CartItemInterface, CategoryInterface, ConfigurableCartItem, GiftCard, ProductInterface, ProductStockStatus,
} from '~/modules/GraphQL/types';
import { Product } from '~/modules/catalog/product/types';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import applyGiftCardToCartGql from './query/applyGiftCardToCart.gql';
import getGiftCardInfoGql from './query/getGiftCardInfo.gql';
import removeGiftCardFromCartGql from './query/removeGiftCardFromCart.gql';
import { UseCartErrors, UseCartInterface } from './useCart';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface, GIFTCARD extends GiftCard>(): UseCartInterface<
  CART,
  CART_ITEM,
  PRODUCT,
  GIFTCARD
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
    getGiftCardInfo: null,
    applyGiftCard: null,
    removeGiftCard: null,
  });
  const { app } = useContext();
  const { app: { i18n } } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { query, mutate } = useApi();
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  /**
   * Check if cart only has giftCart type products
   * @param cart
   *
   * @return boolean
   */
  const hasOnlyGiftCards: () => Ref<boolean> = () => {
    const cartCategories = [];
    cart.value?.items?.forEach((item: ConfigurableCartItem) => cartCategories.push(...item.product.categories));
    return ref(cartCategories.length > 0 && cartCategories.every((category: CategoryInterface) => category.name.includes('Gutscheine')));
  };

  const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        if (state.cart) state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
    product, quantity, productConfiguration, seatPositions, variantSku, customQuery, customHeaders,
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        seatPositions,
        variantSku,
        customQuery,
        customHeaders,
      });

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      await load();
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery, customHeaders }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
        customHeaders,
      });

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        error.value.updateItemQty = null;
        await load();
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    load();
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
        ?.uid;
    }
    const inStock = product?.stock_status === ProductStockStatus.InStock;
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  function getGiftCardErrorMessage(message: string, giftCardCode: string): string {
    if (message.includes('Used')) {
      return i18n.t('The {0} gift card was already used.', {
        0: giftCardCode,
      }) as string;
    }
    return i18n.t('The {0} gift card is not active.', {
      0: giftCardCode,
    }) as string;
  }

  const getGiftCardInfo = async (giftCardCode: string) => {
    Logger.debug('useCart.getGiftCardInfo');

    let giftCard = {};

    try {
      loading.value = true;
      giftCard = await query(getGiftCardInfoGql, { code: giftCardCode });
      error.value.getGiftCardInfo = null;
    } catch (err) {
      error.value.getGiftCardInfo = err;
      Logger.error('useCart/getGiftCardInfo', err);
    } finally {
      loading.value = false;
    }

    return giftCard as GIFTCARD;
  };

  const applyGiftCard = async ({ giftCardCode }): Promise<void> => {
    Logger.debug('useCart.applyGiftCard');

    try {
      loading.value = true;
      const { errors } = await mutate(applyGiftCardToCartGql, { cartId: cart.value.id, giftCardCode });

      if (errors) {
        error.value.applyGiftCard = {
          name: 'applyGiftCard',
          message: getGiftCardErrorMessage(errors[0].message, String(giftCardCode)),
        };
      }
    } catch (err) {
      error.value.applyGiftCard = err;
      Logger.error('useCart/applyGiftCard', err);
    } finally {
      loading.value = false;
    }
  };

  const removeGiftCard = async ({ giftCardCode }): Promise<void> => {
    Logger.debug('useCart.removeGiftCard');

    try {
      loading.value = true;

      await mutate(removeGiftCardFromCartGql, { cartId: cart.value.id, giftCardCode });

      error.value.removeGiftCard = null;
    } catch (err) {
      error.value.removeGiftCard = err;
      Logger.error('useCart/removeGiftCard', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
    hasOnlyGiftCards,
    getGiftCardInfo,
    applyGiftCard,
    removeGiftCard,
  };
}

export default useCart;
export * from './useCart';
