import { gql } from '~/utils/graphql';

export const getBikeDeliveryByClientIdQuery = gql(`
    query BikeDelivery($clientId: String!) {
        bikeDelivery(clientId: $clientId) {
            notificationSent,
            estimatedDeliveryWeek {
                year,
                week
            },
            plannedProductionWeek {
                year,
                week
            }
        }
    }
`);

export type BikeDelivery = {
    notificationSent: boolean;
    estimatedDeliveryWeek: {
        year: number;
        week: number;
    },
    plannedProductionWeek: {
        year: number;
        week: number;
    }
};
