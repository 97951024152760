import { gql } from './utils';

export const companyBikeFormMutation = gql(`
    mutation SubmitCompanyBike($input: CompanyBikeFormInput!) {
      submitCompanyBikeForm(input: $input) {
          errors {
              field
              message
          }
      }
    }
`);

export type ContactFormOutput = {
  errors: { field: string, message: string }[];
};

export type CompanyBikeFormInput = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  bike: string;
  size?: string;
  frame_size?: string;
  provider: string;
  code: string;
  comment: string;
  recaptcha?: string;
};
