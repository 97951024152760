import { ComputedRef } from '@nuxtjs/composition-api';

import { useUser } from '~/modules/customer/composables/useUser';
import type { Customer } from '~/modules/GraphQL/types';
import { useFrametoolApi } from '~/modules/catalog/framesize/useFrametoolApi';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';

import {
  FrametoolLocalStorage, MatchingBikeInfo, UseFrametoolInterface, UserData,
} from './useFrametool';

const FRAMETOOL_STORAGE = 'frametool';

const getUserDataFromStore = (user: ComputedRef<Customer>): UserData => ({
  gender: user.value?.gender?.toString() || '',
  height: user.value?.custom?.bk_height?.toString() || '',
  weight: user.value?.custom?.bk_weight?.toString() || '',
  stepLength: user.value?.custom?.bk_step_length?.toString() || '',
});

const getUserDataFromLocalStorage = (localStorageFrametool: FrametoolLocalStorage): UserData => ({
  gender: localStorageFrametool?.userData?.gender || '',
  height: localStorageFrametool?.userData?.height || '',
  weight: localStorageFrametool?.userData?.weight || '',
  stepLength: localStorageFrametool?.userData?.stepLength || '',
});

export function useFrametool(): UseFrametoolInterface {
  const { isAuthenticated, load: loadUser, user } = useUser();
  const { setShowMatchingBikes: setShowMatchingBikesApi } = useFrametoolApi();

  const getUserData = async (): Promise<UserData> => {
    if (!isAuthenticated.value) {
      const frametoolData = await getItem(FRAMETOOL_STORAGE);
      return getUserDataFromLocalStorage(frametoolData);
    }

    if (!user.value) {
      await loadUser();
    }
    return getUserDataFromStore(user);
  };

  const setUserData = async (data: UserData) => {
    if (isAuthenticated.value) {
      return;
    }

    await mergeItem(FRAMETOOL_STORAGE, { userData: data });
  };

  const getShowMatchingBikes = async (): Promise<boolean> => {
    if (!isAuthenticated.value) {
      const frametoolData = await getItem(FRAMETOOL_STORAGE);
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const showMatchingBikes = (frametoolData && frametoolData['showMatchingBikes']);
      return !!showMatchingBikes;
    }

    if (!user.value) {
      await loadUser();
    }
    return !!user.value?.custom?.bk_show_matching_bikes;
  };

  const setShowMatchingBikes = async (showMatchingBikes: boolean) => {
    if (isAuthenticated.value) {
      await setShowMatchingBikesApi(showMatchingBikes);
      await loadUser();
      return;
    }

    await mergeItem(FRAMETOOL_STORAGE, { showMatchingBikes });
  };

  const getMatchingBikes = async (): Promise<MatchingBikeInfo[]> => {
    if (!isAuthenticated.value) {
      const frametoolData = await getItem(FRAMETOOL_STORAGE);
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const matchingBikes = (frametoolData && frametoolData['matchingBikes']);
      return matchingBikes ?? [];
    }

    if (!user.value) {
      await loadUser();
    }
    const matchingBikes = user.value?.custom?.bk_matching_bikes || '';
    return JSON.parse(matchingBikes).map((bike: any) => {
      const {
        sku, size_index: sizeIndex, size_label: sizeLabel, in_stock: inStock,
      } = bike;
      const matchingBike: MatchingBikeInfo = {
        sku,
        sizeIndex,
        sizeLabel,
        inStock,
      };
      return matchingBike;
    });
  };

  const setMatchingBikes = async (matchingBikes: MatchingBikeInfo[]): Promise<void> => {
    if (isAuthenticated.value) {
      return;
    }

    await mergeItem(FRAMETOOL_STORAGE, { matchingBikes });
  };

  return {
    getUserData, setUserData, getShowMatchingBikes, setShowMatchingBikes, getMatchingBikes, setMatchingBikes,
  };
}

export * from './useFrametool';
export default useFrametool;
