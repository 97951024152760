


































import {
defineComponent, Ref, ref, useContext,
} from '@nuxtjs/composition-api';
import { ProductDetailsQuery } from '@vue-storefront/magento-api/lib/types/GraphQL';
import { merge } from 'lodash-es';
import BKLink from '~/components/BK/BKLink.vue';
import BKProductCard from '~/components/BK/BKProductCard.vue';
import { Product, useApi, useProduct } from '~/composables';
import { ProductWithCommonProductCardProps, useGetCommonProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import getProductPriceAndCustomBySku from '~/modules/catalog/product/queries/getProductPriceAndCustomBySku.gql';
import { CmsLink } from './models/Link.model';

export default defineComponent({
  name: 'ProductsGrid',
  components: { BKProductCard, BKLink },
  props: {
    itemsPerRow: { type: Number, default: 2 },
    headline: { type: String, default: '' },
    productSKUs: { type: Object, default: () => {} },
    link: { type: Array, default: () => [] },
  },
  setup(props) {
    const context = useContext();

    const { productSKUs, link } = props;
    const skus = productSKUs.items as string[];
    const hasLink : boolean = link && link[0] as boolean;
    const linkProp: CmsLink = hasLink ? link[0] as CmsLink : null;
    let productsCount = 0;

    const products: Ref<ProductWithCommonProductCardProps[]> = ref([]);
    const { getProductList } = useProduct();
    const { query } = useApi();
    const productsOrdered: Ref<ProductWithCommonProductCardProps[]> = ref([]);

    const fetchProducts = async () => {
      if (skus.length === 0) {
        products.value = [];
        return;
      }

      const result = await getProductList({
        filter: { sku: { in: skus } },
      });

      const requests = (result.items as Product[]).map(async (product: Product) => {
        const { data } = await query<ProductDetailsQuery>(
          getProductPriceAndCustomBySku,
          {
            search: '',
            filter: {
              sku: {
                eq: product?.sku,
              },
            },
          },
        );

        return merge(product, data.products?.items?.[0] as Product);
      });

      // products may be null if the product is not found
      // eslint-disable-next-line promise/always-return
      Promise.all(requests).then(() => {
        products.value = (result.items as Product[])
          .filter((product) => !!product)
          .map((product, index) => {
            const commonProps = useGetCommonProps(context, product, index);

            return {
              ...product,
              commonProps,
            };
          });

        skus.forEach((sku: string) => {
          products.value.forEach((product: ProductWithCommonProductCardProps) => {
            if (sku === product.sku) {
              productsOrdered.value.push(product);
            }
          });
        });
      }).catch((error) => console.log(error));
    };

    fetchProducts();
    productsCount = products.value.length;

    return {
      linkProp,
      products,
      productsCount,
      productsOrdered,
    };
  },
  methods: {
    getFlexBasis(index: number) {
      if (this.itemsPerRow === 2) {
        return 'products-grid__product--flex-basis-49';
      }

      const lastRowQuantity: number = this.productsCount % this.itemsPerRow;
      const isLastRowComplete: boolean = lastRowQuantity === 0;
      const isProductInLastRow: boolean = index + 1 > this.productsCount - lastRowQuantity;

      if (isProductInLastRow) {
        return isLastRowComplete ? 'products-grid__product--flex-basis-31' : 'products-grid__product--flex-basis-49';
      }

      return 'products-grid__product--flex-basis-31';
    },
  },
});
