import {
  ref, set,
} from '@nuxtjs/composition-api';
import { useUiHelpers } from '~/composables';
import { getFilterConfig } from '~/modules/catalog/category/config/FiltersConfig';
import { FilterTypeEnum } from '~/modules/catalog/category/config/config';
import type { Aggregation, AggregationOption } from '~/modules/GraphQL/types';

export interface SelectedFiltersInterface {[p: string]: string[]}

export interface RemovableFilterInterface {
  id: string;
  name: string;
  label: string;
  value: string;
  type: string;
}

export interface FiltersInterface {
  component: string;
  isActive: boolean;
}

export const filterInitialState: FiltersInterface[] = [
  {
    component: 'manufacturer',
    isActive: false,
  },
  {
    component: 'model_year',
    isActive: false,
  },
  {
    component: 'material',
    isActive: false,
  },
  {
    component: 'manufacturer_weight',
    isActive: false,
  },
  {
    component: 'groupset_type',
    isActive: false,
  },
  {
    component: 'price',
    isActive: false,
  },
  {
    component: 'category_uid',
    isActive: true,
  },
  {
    component: 'wheel_size',
    isActive: false,
  },
  {
    component: 'frame_shape',
    isActive: false,
  },
  {
    component: 'area_of_use',
    isActive: false,
  },
  {
    component: 'manufacturer_circuit',
    isActive: false,
  },
  {
    component: 'drive_type',
    isActive: false,
  },
  {
    component: 'transmission',
    isActive: false,
  },
  {
    component: 'manufacturer_engine',
    isActive: false,
  },
  {
    component: 'battery',
    isActive: false,
  },
  {
    component: 'manufacturer_suspension',
    isActive: false,
  },
  {
    component: 'range_extender_optional',
    isActive: false,
  },
  {
    component: 'fork_travel',
    isActive: false,
  },
  {
    component: 'body_height_kids',
    isActive: false,
  },
  {
    component: 'size',
    isActive: false,
  },
  {
    component: 'show_as_new',
    isActive: false,
  },
  {
    component: 'am_on_sale',
    isActive: false,
  },
];

export function useFilters() {
  // @ts-ignore
  const { getFacetsFromURL } = useUiHelpers();

  const getSelectedFiltersFromUrl = () => {
    const selectedFilterValues = {};
    const { filters } = getFacetsFromURL();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(filters).forEach((filter) => {
      selectedFilterValues[filter] = filters[filter];
    });

    return selectedFilterValues;
  };

  const selectedFilters = ref<SelectedFiltersInterface>(getSelectedFiltersFromUrl());

  const isFilterSelected = (name: string, value: string) => {
    const selected = (selectedFilters.value[name] ?? []).find((selectedValue) => selectedValue === value);

    return selected ?? '';
  };

  const removeFilter = (attrCode: string, valToRemove: string) => {
    if (!selectedFilters.value[attrCode]) return;
    selectedFilters.value[attrCode] = selectedFilters.value[attrCode].filter((value) => value !== valToRemove);
  };

  const selectFilter = (filter: Aggregation, option: AggregationOption) => {
    const config = getFilterConfig(filter.attribute_code);
    if (!selectedFilters.value[filter.attribute_code]) {
      set(selectedFilters.value, filter.attribute_code, []);
    }

    if (config.type === FilterTypeEnum.RADIO || config.type === FilterTypeEnum.YES_NO) {
      selectedFilters.value[filter.attribute_code] = [option.value];
      return;
    }

    if (selectedFilters.value[filter.attribute_code].find((f) => f === option.value)) {
      selectedFilters.value[filter.attribute_code] = selectedFilters.value[
        filter.attribute_code
      ]?.filter((f) => f !== option.value);
      return;
    }

    selectedFilters.value[filter.attribute_code].push(String(option.value));
  };

  const getRemovableFilters = (filters: Aggregation[], selected: SelectedFiltersInterface): RemovableFilterInterface[] => {
    const result = [];

    filters.forEach((filter) => {
      filter.options.forEach((option) => {
        if ((selected[filter.attribute_code] ?? []).includes(option.value)) {
          const filterConfig = getFilterConfig(filter.attribute_code);

          result.push({
            id: filter.attribute_code, name: filter.label, label: option.label, value: option.value, type: filterConfig.type,
          });
        }
      });
    });

    return result;
  };

  return {
    getSelectedFiltersFromUrl,
    isFilterSelected,
    removeFilter,
    selectFilter,
    selectedFilters,
    getRemovableFilters,
    filterInitialState,
  };
}

export default useFilters;
