import RendererTypesEnum from '~/modules/catalog/category/components/filters/renderer/RendererTypesEnum';
import type { FilterConfigInterface } from '~/modules/catalog/category/config/FiltersConfig';

export enum FilterTypeEnum {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SWATCH_COLOR = 'swatch_color',
  YES_NO = 'yes_no',
  SLIDER = 'slider',
  TREE = 'tree',
}

/**
 * Override this to add/modify filters renderers and data
 *
 * @attrCode: Magento attribute code
 * @type: internal filter type
 * @component: filter renderer component
 * @disabled: flag to disable filter on the front; disabled filter will be not rendered
 */
export default function config(): FilterConfigInterface[] {
  return [
    {
      attrCode: 'manufacturer',
    },
    {
      attrCode: 'model_year',
    },
    {
      attrCode: 'material',
    },
    {
      attrCode: 'manufacturer_weight',
      type: FilterTypeEnum.SLIDER,
      component: RendererTypesEnum.SLIDER,
    },
    {
      attrCode: 'groupset_type',
    },
    {
      attrCode: 'price',
      type: FilterTypeEnum.SLIDER,
      component: RendererTypesEnum.SLIDER,
    },
    {
      attrCode: 'category_uid',
      type: FilterTypeEnum.TREE,
      component: RendererTypesEnum.TREE,
    },
    {
      attrCode: 'wheel_size',
    },
    {
      attrCode: 'area_of_use',
    },
    {
      attrCode: 'size',
    },
    {
      attrCode: 'transmission',
    },
    {
      attrCode: 'manufacturer_engine',
    },
    {
      attrCode: 'battery',
      type: FilterTypeEnum.SLIDER,
      component: RendererTypesEnum.SLIDER,
    },
    {
      attrCode: 'range_extender_optional',
    },
    {
      attrCode: 'fork_travel',
    },
    {
      attrCode: 'body_height_kids',
    },
    {
      attrCode: 'frame_shape',
    },
    {
      attrCode: 'drive_type',
    },
    {
      attrCode: 'manufacturer_suspension',
    },
    {
      attrCode: 'manufacturer_circuit',
    },
    {
      attrCode: 'show_as_new',
    },
    {
      attrCode: 'am_on_sale',
    },
  ];
}
