






































import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { Image } from '~/cms/models/Image.model';
import { optimizeImage } from '~/helpers/image';
import { isBreakpointLg } from './helpers/breakpoints';
import { EnumDevice } from './helpers/devices';

export default defineComponent({
  name: 'CmsHeader',
  components: {
    SfIcon,
  },
  props: {
    headline: { type: String, default: '' },
    image: { type: Object, default: () => {} },
    caption: { type: String, default: '' },
  },
  setup(props) {
    const imageProp: Image = props.image as Image;
    const optimizedImage: string = imageProp.filename;

    return {
      optimizedImage,
      imageProp,
      EnumDevice,
    };
  },
  mounted() {
    const self = this.$refs.self as HTMLElement;

    this.optimizedImage = `url('${optimizeImage(this.imageProp, `${self.clientWidth}x${self.clientHeight}`)}')`;
  },
  methods: {
    scrollDown(target: EnumDevice = EnumDevice.DESKTOP) : void {
      // Only let users to click when they are on mobile width
      const isMobile: boolean = isBreakpointLg(window);

      if ((isMobile && target === EnumDevice.MOBILE) || (!isMobile && target === EnumDevice.DESKTOP)) {
        window.scrollBy(0, 150);
      }
    },
  },
});
