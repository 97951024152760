





































  import { defineComponent } from '@nuxtjs/composition-api';
import { ISbRichtext } from 'storyblok-js-client';
import RichText from './helpers/RichText.vue';
import { isRichTextEmpty } from './helpers/renderRichText';

  interface IRichTextElement extends HTMLElement {
    richtext: string
  }

  enum TagType {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
  }

  enum BackgroundColor {
    LightGray = 'bg-zinc-100',
    White = 'bg-white',
  }

  export default defineComponent({
    name: 'HeadlineText',
    components: { RichText },
    props: {
      headline: { type: Object, default: () => {} },
      text: { type: Object, default: () => {} },
      backgroundColor: { type: String, default: BackgroundColor.LightGray },
      onlyApplyStyling: { type: Boolean, default: false },
    },
    setup(props) {
      const headlineElement: string = TagType.H1;
      const headlineTag: string = TagType.H1;

      return {
        headlineElement,
        headlineTag,
        isRichTextEmpty,
        richText: props.text as ISbRichtext,
        richHeadline: props.headline as ISbRichtext,
      };
    },
    mounted() {
      const headlineRichTextElement: IRichTextElement = this.$refs.headlineRichText as IRichTextElement;

      if (headlineRichTextElement) {
        const headlineRichText : string = headlineRichTextElement?.richtext;

        this.headlineTag = headlineRichText?.slice(
          headlineRichText.indexOf('<') + 1,
          headlineRichText.indexOf('>'),
        );

        this.headlineElement = this.onlyApplyStyling ? 'p' : this.headlineTag;
      }
    },
    methods: {
      headlineClass(headlineTag: string): string {
        let classNames = '';
        switch (headlineTag) {
          case TagType.H1:
            classNames = 'md:col-end-9';
            break;
          case TagType.H2:
            classNames = 'md:col-end-6';
            break;
          case TagType.H3:
            classNames = 'md:col-end-4';
            break;
          case TagType.H4:
            classNames = 'md:col-end-4';
            break;
          default:
            classNames = 'md:col-end-4';
        }

        return `${classNames} ${!isRichTextEmpty(this.text as ISbRichtext) ? 'md:mb-12 mb-6' : 'mb-0'} ${headlineTag}`;
      },
    },

  });
