export const discount = (regularPrice: number | string | null, discountPrice: number | string | null) => {
  if (!regularPrice || !discountPrice) {
    return null;
  }

  // we need to remove all non-numeric characters from the price string, but still need to keep the comma
  // price is coming as 1.149,00 €
  const regular = typeof regularPrice === 'string' ? Number.parseFloat(regularPrice.replace(/[^\d,-]/g, '')) : regularPrice;
  const special = typeof discountPrice === 'string' ? Number.parseFloat(discountPrice.replace(/[^\d,-]/g, '')) : discountPrice;

  return Math.round(((regular - special) / regular) * 100);
};
