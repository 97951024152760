






























import { defineComponent, ref, Ref } from '@nuxtjs/composition-api';
import BKButton from '~/components/BK/BKButton.vue';
import { optimizeImage } from '~/helpers/image';
import { arrowRightIcon } from './helpers/customIcons';
import RichText from './helpers/RichText.vue';
import { Button, EnumButtonVariant } from './models/Button.model';
import { Image } from './models/Image.model';

  enum EnumFontSize {
    LARGE = 'large',
    SMALL = 'small',
  }

  export default defineComponent({
    name: 'CallToAction',
    components: { RichText, BKButton },
    props: {
      text: { type: Object, default: () => {} },
      backgroundImage: { type: Object, default: () => {} },
      fontSize: { type: String, default: EnumFontSize.LARGE },
      button: { type: Array, default: () => [] },
    },
    setup(props) {
      const buttonProperties: Button = props.button[0] as Button;
      const imageProp: Image = props.backgroundImage as Image;
      const optimizedImage: Ref<string> = ref(imageProp.filename);

      return {
        imageProp,
        optimizedImage,
        buttonProperties,
        EnumFontSize,
        EnumButtonVariant,
        arrowRightIcon,
      };
    },
    mounted() {
      const self = this.$refs.self as HTMLElement;

      this.optimizedImage = `url('${optimizeImage(this.imageProp, `${self.clientWidth}x${self.clientHeight}`)}')`;
    },
  });
