




























import {
defineComponent,
} from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { useStoryblokStartWith } from '~/composables/useStoryblok';
import { arrowRightIcon } from './helpers/customIcons';

enum JobListBackgroundColor {
  WHITE = 'white',
  GREY = 'grey',
}

interface IJobPage {
  name: string,
  uuid: string,
  full_slug: string,
  published_at: string
}

export default defineComponent({
  name: 'JobList',
  components: {
    SfIcon,
  },
  props: {
    backgroundColor: { type: String, default: JobListBackgroundColor.WHITE },
  },
  setup() {
    const { stories } = useStoryblokStartWith('jobs/', 'body');

    return {
      jobs: stories as IJobPage[],
      arrowRightIcon,
      JobListBackgroundColor,
    };
  },
});

