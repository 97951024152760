











import { defineComponent } from '@nuxtjs/composition-api';
import { PropType } from 'vue';

export default defineComponent({
  name: 'BikeDeliveryContent',
  props: {
    headline: { type: String as PropType<any>, required: true },
  },
});
