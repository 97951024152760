










import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    open: { type: Boolean, default: false },
  },
});
