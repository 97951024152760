
















import { defineComponent } from '@nuxtjs/composition-api';
import KeyFact from './components/KeyFact.vue';

enum EnumBackgroundColor {
  GREY = 'grey',
  WHITE = 'white',
}

interface IKeyFact {
  _uid: string,
  headline: string,
  text: string
}

export default defineComponent({
  name: 'KeyFacts',
  components: {
    KeyFact,
  },
  props: {
    items: { type: Array, default: () => [] },
    backgroundColor: { type: String, default: '' },
  },
  setup(props) {
    return {
      keyFacts: props.items as IKeyFact[],
      EnumBackgroundColor,
    };
  },
});
