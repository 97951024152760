























import { defineComponent } from '@nuxtjs/composition-api';
import Teaser from './components/Teaser.vue';

export default defineComponent({
  name: 'Teasers',
  components: {
    Teaser,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  setup(props) {
    const numberOfItems = props.items.length;
    return {
      numberOfItems,
    };
  },

});
