



































import { defineComponent } from '@nuxtjs/composition-api';

import { PropType } from 'vue';
import BKButton from '~/components/BK/BKButton.vue';
import BKInput from '~/components/BK/BKInput.vue';

import BikeDeliveryContent from './BikeDeliveryContent.vue';
import { EnumButtonVariant } from '../models/Button.model';

export default defineComponent({
  name: 'BikeDeliveryForm',
  components: {
    BikeDeliveryContent,
    BKButton,
    BKInput,
  },
  props: {
    onSubmit: { type: Function as PropType<(clientId: string) => void>, required: true },
    loading: { type: Boolean, default: () => false },
    validationError: { type: String, default: () => '' },
  },
  setup() {
    return { EnumButtonVariant };
  },
  data() {
    return { clientId: '' };
  },
  methods: {
    handleSubmit() {
      this.onSubmit(this.clientId);
    },
  },
});
