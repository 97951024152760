



































import { defineComponent, ref, Ref } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import RichText from '~/cms/helpers/RichText.vue';

enum EnumAccordeonItemIcon {
  PLUS = 'plus',
  MINUS = 'minus',
}

interface AccordeonItem {
  _uid: string,
  heading: string,
  text: object,
  type: string
}

interface AccordeonItemState {
  index: number,
  isOpen: boolean
}

export default defineComponent({
  components: {
    SfIcon,
    RichText,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  setup(props) {
    const openStates: Ref<AccordeonItemState[]> = ref([]);
    props.items.forEach((item: AccordeonItem, index: number) => openStates.value.push({ index, isOpen: false }));
    const typedItems: AccordeonItem[] = props.items as AccordeonItem[];

    return {
      openStates,
      typedItems,
    };
  },
  methods: {
      openAccordeon(index: number): void {
        const { isOpen } = this.openStates.find((state: any) => state.index === index);
        this.openStates.find((state: any) => state.index === index).isOpen = !isOpen;
      },
      getIconByState(isOpen: boolean) {
        return isOpen ? EnumAccordeonItemIcon.MINUS : EnumAccordeonItemIcon.PLUS;
      },
      isOpen(index: number): boolean {
        return this.openStates[index].isOpen;
      },
    },
});
