
































import { defineComponent } from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import { companyBikeFormMutation, CompanyBikeFormInput } from '~/graphql/submitCompanyBikeForm';
import FormContent from './Form.vue';

export default defineComponent({
  name: 'DienstradForm',
  components: {
    FormContent,
  },
  setup() {
    const { mutate } = useApi();

    return { mutate };
  },
  data() {
    return {
      formSubmitted: false,
      isLoading: true,
      error: false,
    };
  },
  methods: {
    async handleSubmitForm(data: CompanyBikeFormInput) {
      this.error = null;
      this.isLoading = true;
      const result = await this.mutate(companyBikeFormMutation, { input: data });

      if (result.errors?.length > 0) {
        this.error = true;
      }

      this.formSubmitted = true;
      this.isLoading = false;
      (this.$refs.wrapper as HTMLDivElement).scrollIntoView();
    },
  },
});
