





































import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { openCmsLink } from '~/cms/helpers/urls';
import { Image } from '~/cms/models/Image.model';
import { Link } from '~/cms/models/Link.model';
import { optimizeImage } from '~/helpers/image';

export default defineComponent({
  name: 'Teaser',
  components: {
    SfIcon,
  },
  props: {
    item: { type: Object, default: () => {} },
    numberOfItems: { type: Number, default: 1 },
  },
  setup(props) {
    const image : Image = props.item.image.url;
    const optimizedImage: string = '';

    const link = props.item.link as Link;
    const hasLinkUrl: boolean = link && Boolean(link.url || (link.cached_url || link.id));

    return {
      image,
      optimizedImage,
      link,
      hasLinkUrl,
    };
  },
  mounted() {
    const selfTeaser = this.$refs.selfTeaser as HTMLElement;

    this.optimizedImage = `url('${optimizeImage(this.image, `${selfTeaser.clientWidth}x${selfTeaser.clientHeight}`)}')`;
  },
  methods: {
    openLink(link: Link) : void {
       // Here we want to see if we receive a normal url or an internal one
       if (!this.hasLinkUrl) {
        return;
      }

      openCmsLink(link, this.$router);
    },
  },
});
