/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  computed,
  onMounted,
  ref,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { ProductStockStatus } from '@vue-storefront/magento-api';
import { debounce } from 'lodash-es';
import {
  BundleCartItem,
  CartItemInterface,
  ConfigurableCartItem,
  SimpleCartItem,
  useExternalCheckout,
  useImage,
  useProduct,
  useUiNotification,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import type { UseCartViewInterface } from '~/modules/checkout/composables/useCartView/useCartView';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useUser } from '~/modules/customer/composables/useUser';

enum ProductTypenameEnum {
  SIMPLE_ITEM = 'SimpleCartItem',
  GIFT_CARD_ITEM = 'MageWorxGiftCardsCartItem',
}

/**
 * Allows loading and manipulating cart view.
 *
 * See the {@link UseCartViewInterface} for a list of methods and values available in this composable.
 */
export function useCartView(): UseCartViewInterface {
  const { localePath, app: { i18n } } = useContext();
  const { initializeCheckout } = useExternalCheckout();
  const { getMagentoImage, imageSizes } = useImage();
  const router = useRouter();
  const { getProductPath } = useProduct();
  const {
    cart,
    removeItem,
    updateItemQty,
    load: loadCart,
    loading,
    hasOnlyGiftCards,
  } = useCart();
  const { isAuthenticated } = useUser();
  const { send: sendNotification, notifications } = useUiNotification();

  if (!cart.value?.id) {
    loadCart();
  }

  const products = computed(() => cartGetters
    .getItems(cart.value)
    .filter(Boolean)
    .map((item) => ({
      ...item,
      product: {
        ...item.product,
        ...[(item as ConfigurableCartItem).configured_variant ?? {}],
        original_sku: item.product.sku,
      },
    })));

  const totals = computed(() => cartGetters.getTotals(cart.value));
  const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  const getAttributes = (product: ConfigurableCartItem) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product.__typename === 'MageWorxGiftCardsCartItem') {
      return [];
    }

    const seatPositionsSuffixes = {
      belly_button_height: 'cm',
      weight: 'kg',
      height: 'cm',
    };

    const seatPositions = [];

    if (product.seat_position) {
      Object.keys(product.seat_position).forEach((key: string) => {
        if (key !== '__typename' && product.seat_position[key]) {
          seatPositions.push({
            option_label: i18n.t(key),
            value_label: seatPositionsSuffixes[key] ? `${product.seat_position[key]} ${seatPositionsSuffixes[key]}` : i18n.t(product.seat_position[key]),
          });
        }
      });

      return [...product.configurable_options || [], ...seatPositions];
    }

    return [];
  };

  const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
  const isRemoveModalVisible = ref(false);
  const itemToRemove = ref<CartItemInterface>();

  onMounted(() => {
    if (!cart.value.id) {
      loadCart();
    }
  });

  const goToCheckout = async () => {
    const redirectUrl = initializeCheckout({
      baseUrl: !isAuthenticated.value
        ? '/checkout/benutzerkonto'
        : (hasOnlyGiftCards().value
          ? '/checkout/rechnung'
          : '/checkout/lieferung'),
    });
    await router.push(localePath(redirectUrl));
  };

  const showRemoveItemModal = ({ product }: { product: CartItemInterface }) => {
    if (notifications.value.length > 0) {
      notifications.value[0].dismiss();
    }

    isRemoveModalVisible.value = true;
    itemToRemove.value = product;
  };

  const removeItemAndSendNotification = async (product: CartItemInterface) => {
    await removeItem({ product });
    isRemoveModalVisible.value = false;
    await loadCart();

    sendNotification({
      id: Symbol('product_removed'),
      message: i18n.t('{0} has been successfully removed from your cart', {
        0: cartGetters.getItemName(
          product,
        ),
      }) as string,
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Product removed',
    });
  };

  const delayedUpdateItemQty = debounce(
    (params) => updateItemQty(params),
    1000,
  );

  function isOutOfStock(product: CartItemInterface | ConfigurableCartItem | SimpleCartItem) {
    // eslint-disable-next-line no-underscore-dangle
    if ([ProductTypenameEnum.SIMPLE_ITEM, ProductTypenameEnum.GIFT_CARD_ITEM].includes(product.__typename as ProductTypenameEnum)) {
      return (product.product.stock_status === ProductStockStatus.OutOfStock || product.product.quantity === 0);
    }
    // eslint-disable-next-line max-len
    return ((product as ConfigurableCartItem).configured_variant.stock_status === ProductStockStatus.OutOfStock || (product as ConfigurableCartItem).configured_variant.quantity === 0) || (product.product.stock_status === ProductStockStatus.OutOfStock);
  }

  return {
    showRemoveItemModal,
    removeItemAndSendNotification,
    delayedUpdateItemQty,
    goToCheckout,
    getAttributes,
    getBundles,
    isOutOfStock,
    getMagentoImage,
    getProductPath,
    loading,
    isAuthenticated,
    products,
    isRemoveModalVisible,
    itemToRemove,
    totals,
    totalItems,
    imageSizes,
    discount,
    cartGetters,
  };
}

export default useCartView;
export * from './useCartView';
