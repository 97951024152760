export default `
  query getGiftCardInfo($code: String = "") {
    mwGiftCardInfo(code: $code) {
      status
      valid_till
      balance {
          value
          currency_code
          label
      }
    }
  }
`;
