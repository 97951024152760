







































import { defineComponent } from '@nuxtjs/composition-api';
import RichText from './helpers/RichText.vue';

export default defineComponent({
  name: 'CmsTable',
  components: {
    RichText,
  },
  props: {
    headline: { type: Object, default: () => {} },
    table: { type: Object, default: () => {} },
  },
});
