































































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import {
  TextInput,
  Select as SelectControl,
  TextArea,
} from '~/components/Forms';
import { bikeNameQueryParameter } from '~/helpers/routes';
import BKButton from '~/components/BK/BKButton.vue';
import type { CompanyBikeFormInput } from '~/graphql/submitCompanyBikeForm';
import { useRecaptcha } from '~/composables/utils/recaptcha';
import TosCheckbox from '~/components/Forms/TosCheckbox.vue';

type Provider = {
  name: any;
  description: any;
  rules: string;
};

export default defineComponent({
  name: 'DienstradForm',
  components: {
    TextInput,
    SelectControl,
    TosCheckbox,
    TextArea,
    BKButton,
    ValidationObserver,
  },
  props: {
    onSubmit: {
      type: Function as PropType<(data: any) => void>,
      required: true,
    },
  },
  setup() {
    const getRecaptchaToken = useRecaptcha();
    return { getRecaptchaToken };
  },
  data(): {
    tos: boolean;
    providers: Provider[];
    form: CompanyBikeFormInput;
    recaptchaError: null | string;
  } {
    const form: CompanyBikeFormInput = {
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      bike: '',
      size: '',
      frame_size: '',
      provider: '',
      code: '',
      comment: '',
    };

    const providers: Provider[] = [
      {
        name: this.$t('Bikeleasing'),
        description: this.$t('Bikeleasing code', {
          length: 8,
          example: 'B8W7-XY8N',
        }),
        rules: 'required|min:8|max:9',
      },
      {
        name: this.$t('Leasemybike'),
        description: this.$t('Bikeleasing code', {
          length: 9,
          example: 'EBZ78DRI9',
        }),
        rules: 'required|min:9|max:9',
      },
      {
        name: this.$t('Jobrad'),
        description: this.$t('Registered e-mail address'),
        rules: 'required|email',
      },
      {
        name: this.$t('Lease a Bike'),
        description: this.$t('Company bike order code', {
          length: 6,
          example: 'NzQP2e',
        }),
        rules: 'required|min:6|max:6',
      },
      {
        name: this.$t('Firmenradl'),
        description: this.$t('Firmenradl code', {
          example: 'ANRF/2023/02/01469',
        }),
        rules: 'required|min:10|max:20',
      },
      {
        name: this.$t('Ablo'),
        description: this.$t('Ablo code', {
          length: 5,
          example: 'JMHZE',
        }),
        rules: 'required|min:5|max:5',
      },
    ];

    return {
      tos: false,
      providers,
      form,
      recaptchaError: null,
    };
  },
  computed: {
    activeProvider(): Provider | undefined {
      return this.providers.find(({ name }) => name === this.form.provider);
    },
    options(): { value: string; label: string }[] {
      return this.providers.map((provider) => ({
        value: provider.name,
        label: provider.name,
      }));
    },
  },
  mounted(): void {
    const bike = this.$route.query[bikeNameQueryParameter];

    if (bike && typeof bike === 'string') {
      this.form.bike = bike;
    }
  },
  methods: {
    async submitForm(isValid: boolean): Promise<void> {
      this.recaptchaError = null;
      const recaptcha = await this.getRecaptchaToken();

      if (!recaptcha) {
        this.recaptchaError = this.$tc('validation.required');
        return;
      }

      if (!isValid) return;

      this.onSubmit({ ...this.$data.form, recaptcha });
    },
    isStepLengthRequired(): boolean {
      return this.$data.form.size.trim() === '';
    },
    isSizeRequired(): boolean {
      return this.$data.form.frame_size.trim() === '';
    },
  },
});
