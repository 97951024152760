















  import {
defineComponent, ref,
} from '@nuxtjs/composition-api';
import { useBikeFinderStore } from '~/modules/catalog/category/stores/bikefinder';
import { iFrameCalendly } from './helpers/urls';

enum EnumIFrameAlignment {
  LEFT = 'left',
  ALIGNED_WITH_TEXT = 'alignWithText',
}

export default defineComponent({
  name: 'Calendly',
  components: {},
  props: {
    alignment: { type: String, default: EnumIFrameAlignment.LEFT },
  },
  setup() {
    const bikeFinderStore = useBikeFinderStore();

    const additionalParam = `&a4=${encodeURIComponent(bikeFinderStore.bikefinderUrl)}`;

    const iFrame = ref(iFrameCalendly(bikeFinderStore.bikefinderUrl ? additionalParam : ''));

    return {
      EnumIFrameAlignment,
      iFrame,
    };
  },
});
