import { extend } from 'vee-validate';
import {
 required, email, min, max, min_value, max_value, confirmed, numeric,
} from 'vee-validate/dist/rules';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';

extend('required', required);
extend('email', email);
extend('max', max);
extend('min', min);
extend('min_value', min_value);
extend('max_value', max_value);
extend('password', {
    message: invalidPasswordMsg,
    validate: (value: string) => customerPasswordRegExp.test(value),
});
extend('confirmed', confirmed);
extend('numeric', numeric);
