




import { computed, defineComponent } from '@nuxtjs/composition-api';
import { ISbRichtext } from 'storyblok-js-client';
import { renderRichText } from './renderRichText';

export default defineComponent({
  name: 'RichText',
  props: {
    content: { type: Object, default: () => {} },
  },
  setup(props) {
    const richtext = computed(() => renderRichText(props.content as ISbRichtext));

    return { richtext };
  },
});
