


























































import { defineComponent } from '@nuxtjs/composition-api';
import BKLink from '~/components/BK/BKLink.vue';
import MenuTeaser from './MenuTeaser.vue';
import { MenuEntry } from './Navigation';

export default defineComponent({
  name: 'HeaderNavigationItem',
  components: { MenuTeaser, BKLink },
  props: {
    menuItem: { type: Object, default: () => {} },
    open: { type: Boolean, default: false },
  },
  setup(props) {
    const item = props.menuItem as MenuEntry;

    return {
      item,
      link: item.link?.length > 0 ? item.link[0] : null,
    };
  },
  computed: {
    gridColumns(): string {
      let columnGrid = '';
      const columns = this.item.columns.length;
      const teaserColumn = this.item.teaserColumn && this.item.teaserColumn.length > 0;

      // max columns is 3
      for (let i = 0; i < columns; ++i) {
        columnGrid = `${columnGrid} 1fr`;
      }

      if (teaserColumn) {
        return `${columnGrid} 3fr`;
      }
      // reserve some space at the end so that the grid is looking fine
      return `${columnGrid} ${columns === 2 ? '3fr' : (columns === 3 ? '2fr' : '')}`;
    },
  },
});
