import { Routes } from '~/helpers/routes';
import { Link, LinkTypes } from '../models/Link.model';

// LinkTypes.URL can still have internal links starting with a slash, e.g. /kategorie/... or /produkt/...
export const isExternalLink = (link: Link) => link.linktype === LinkTypes.URL && !(link.url || link.cached_url)?.startsWith('/');

export const openCmsLink = (link: Link, router: any) => {
  if (isExternalLink(link)) {
    const target = link.target || '_self';
    window.open(link.url || link.cached_url, target);
  } else {
    const url = link.url || link.cached_url;
    // for internal storyblok links we need to prepend a slash
    router.push(link.linktype === LinkTypes.URL ? url : `/${url}`);
  }
};

export const expertAdviceUrl = `/${Routes.EXPERT_ADVICE}`;

export const getExpertAdviceLink = (): Link => ({
    _uid: Routes.EXPERT_ADVICE,
    cached_url: expertAdviceUrl,
    linktype: LinkTypes.URL,
    target: '_self',
    url: expertAdviceUrl,
  });

export const iFrameCalendly = (customParams?: string) => `<iframe srcdoc="<div class='calendly-inline-widget' data-url='https://calendly.com/d/5fh-sp5-kd3/expertenberatung?hide_gdpr_banner=1&primary_color=e31e26${customParams}' style='height:1200px;'></div>

<script type='text/javascript' src='https://assets.calendly.com/assets/external/widget.js' async></script>"></iframe>`;
