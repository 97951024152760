import { BaseModel, IBaseModel } from './Base.model';
import { Link } from './Link.model';

export enum EnumButtonVariant {
  BLURRY = 'blurry',
  PRIMARY = 'primary',
}
export interface IButton extends IBaseModel {
  link: Link,
  text: string,
  variant: string,
}

export class Button extends BaseModel {
  public link: Link;

  public text: string;

  public variant: string;

  constructor(button: IButton) {
    super(button);

    this.link = button.link;
    this.text = button.text;
    this.variant = button.variant;
  }
}
