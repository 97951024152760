










import { defineComponent } from '@nuxtjs/composition-api';
import { Checkbox } from '~/components/Forms';

export default defineComponent({
  name: 'TosCheckbox',
  components: {
    Checkbox,
  },
  data() {
    return {
        checked: false,
    };
  },
  methods: {
    getTosLink() {
      return `<a href="/datenschutz" target="_blank" class="underline cursor-pointer">${this.$t('Data Privacy Statement')}</a>`;
    },
  },
});
