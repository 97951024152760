import { unescape } from 'lodash-es';
import DOMPurify from 'isomorphic-dompurify';
import type { Plugin } from '@nuxt/types';

declare module 'vue/types/vue' {
  interface Vue {
    $dompurify(html: string): string;
  }
}

// https://github.com/cure53/DOMPurify/issues/317
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';

DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
    if (node.tagName === 'A') {
        if (!node.hasAttribute('target')) {
            node.setAttribute('target', '_self');
        }

        if (node.hasAttribute('target')) {
            node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'));
        }
    }
});

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
        node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE));
        node.removeAttribute(TEMPORARY_ATTRIBUTE);
        if (node.getAttribute('target') === '_blank') {
            node.setAttribute('rel', 'noopener');
        }
    }
});
const plugin: Plugin = (_, inject) => {
  inject('dompurify', (html: string): string => unescape(DOMPurify.sanitize(html)));
};

export default plugin;
