














































import { defineComponent } from '@nuxtjs/composition-api';
import BKButton from '../components/BK/BKButton.vue';
import RichText from './helpers/RichText.vue';
import { arrowRightIcon } from './helpers/customIcons';
import { Button, EnumButtonVariant } from './models/Button.model';
import { Image } from './models/Image.model';

interface ListItem {
  _uid: string,
  headline: string,
  text: Object,
  image: { url: Image }
}

export default defineComponent({
  name: 'List',
  components: {
    RichText,
    BKButton,
  },
  props: {
    headline: { type: String, default: '' },
    items: { type: Array, default: () => [] },
    columns: { type: String, default: '' },
    text: { type: Object, default: () => {} },
    button: { type: Array, default: () => [] },
  },
  setup(props) {
    const typedListItems: ListItem[] = props.items as ListItem[];

    const buttonProperties: Button = props.button[0] as Button;
    const isItemsLengthOdd: boolean = typedListItems.length % 2 !== 0;

    return {
      typedListItems,
      buttonProperties,
      EnumButtonVariant,
      arrowRightIcon,
      isItemsLengthOdd,
    };
  },
});
