import { useContext } from '@nuxtjs/composition-api';
import { UseContextReturn } from '~/types/core';
import { UseImageInterface } from './useImage';

const imageSizes = {
  productCard: {
    width: 216,
    height: 268,
  },
  productCardHorizontal: {
    width: 140,
    height: 200,
  },
  checkout: {
    imageWidth: 100,
    imageHeight: 100,
  },
  productGallery: {
    thumbWidth: 80,
    thumbHeight: 80,
    imageWidth: 688,
    imageHeight: 432,
  },
  cart: {
    imageWidth: 170,
    imageHeight: 170,
  },
};

/**
 * Extract image path from Magento URL.
 */
export const getProductImage = (context: UseContextReturn, fullImageUrl: string | null) => {
  if (!fullImageUrl) return '';

  const { $config } = context;

  // eslint-disable-next-line prefer-destructuring
  const imageProvider = $config?.imageProvider;
  // eslint-disable-next-line prefer-destructuring
  const magentoBaseUrl = $config?.magentoBaseUrl;

  if (imageProvider !== 'ipx') {
    const url = fullImageUrl.split(`${magentoBaseUrl}`);

    const regex = /cache\/(.*?)\//gm;
    return url[1].replace(regex, '');
  }

  return fullImageUrl;
};

/**
 * Allows extracting image paths from magento URL.
 */
export function useImage(): UseImageInterface {
  const context = useContext();

  const getMagentoImage = (fullImageUrl: string | null) => getProductImage(context, fullImageUrl);

  return {
    getMagentoImage,
    imageSizes,
  };
}

export * from './useImage';
export default useImage;
