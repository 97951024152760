






















































import { defineComponent } from '@nuxtjs/composition-api';
import { ISbRichtext } from 'storyblok-js-client';
import BKLink from '~/components/BK/BKLink.vue';
import { optimizeImage } from '~/helpers/image';
import RichText from './helpers/RichText.vue';
import { isRichTextEmpty } from './helpers/renderRichText';
import { Image } from './models/Image.model';
import { CmsLink } from './models/Link.model';
import { openCmsLink } from './helpers/urls';

enum Alignment {
  ImageRight = 'imageRight',
  ImageLeft = 'imageLeft',
}

export default defineComponent({
  name: 'TextImage',
  components: { RichText, BKLink },
  props: {
    headline: { type: String, required: true },
    image: { type: Object, default: () => {} },
    text: { type: Object, default: () => {} },
    link: { type: Array, default: () => [] },
    alignment: { type: String, default: Alignment.ImageRight },
  },
  setup(props) {
    const hasLink : Boolean = props.link && props.link[0] as Boolean;
    const linkParam : CmsLink = props.link[0] as CmsLink;
    const imageProp: Image = props.image as Image;
    const optimizedImage: string = imageProp.filename;

    return {
      imageProp,
      optimizedImage,
      hasLink,
      linkParam,
      Alignment,
      isRichTextEmpty,
      richText: props.text as ISbRichtext,
    };
  },
  mounted() {
    this.optimizedImage = optimizeImage(this.imageProp, '1000x600');
  },
  methods: {
    openLink() {
      if (!this.hasLink) {
        return;
      }

      openCmsLink(this.linkParam.link, this.$router);
    },
  },
});
