



































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfIcon, SfLoader } from '@storefront-ui/vue';
import { LinkTypes } from '~/cms/models/Link.model';

export default defineComponent({
  name: 'BKButton',
  components: {
    SfButton,
    SfIcon,
    SfLoader,
  },
  props: {
    link: {
      type: Object,
      default: () => {},
    },
    variant: { type: String, default: 'primary', validator: (val: string) => ['primary', 'secondary', 'tertiary'].includes(val) },
    size: { type: String, default: 'md', validator: (val: string) => ['xs', 'sm', 'md', 'lg'].includes(val) },
    disabled: { type: Boolean, default: false },
    suffixIcon: { type: String, default: '' },
    prefixIcon: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    neutral: { type: Boolean, default: false },
    isMailTo: { type: Boolean, default: false },
  },
  setup(props) {
    return {
      classes: computed(() => `bk-button-${props.variant} bk-button-${props.variant}${props.neutral ? '--neutral' : '--default'} bk-button--${props.size} ${props.disabled || props.loading ? `bk-button-${props.variant}--disabled` : ''}`),
      iconSize: computed(() => (props.size === 'sm' ? 'xs' : 'sm')),
    };
  },
  methods: {
    linkRelatedProps(): { target?: string, link?: string } {
      const props: { target?: string, link?: string } = {};
      if (this.link?.url || (this.link?.cached_url && this.link?.id)) {
        props.target = this.link.target;
        props.link = this.link.url || this.link.cached_url;
      }

      if (this.isMailTo) {
        props.link = `mailto:${props.link}`;
      }

      if (this.link?.linktype === LinkTypes.STORY) {
        props.link = `/${props.link}`;
      }

      return props;
    },
  },
});
