var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-form m-auto max-w-3xl grid md:grid-cols-6"},[_c('div',{staticClass:"grid md:col-start-3 md:col-end-6 gap-2"},[_c('span',{staticClass:"text-lg font-semibold leading-[130%]"},[_vm._v(_vm._s(_vm.$t('Contact details')))]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form flex flex-col gap-4",on:{"submit":function($event){$event.preventDefault();(_vm.submitForm(reset))}}},[_c('PersonalDetailsForm',{ref:"personalDetailsForm",on:{"updated-form":_vm.personalDetailsFormUpdated}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":"regarding"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"sf-select mb-4 flex flex-col"},[_c('SelectControl',{attrs:{"class-name":"md:col-span-2","label":_vm.$t('Regarding'),"options":_vm.dropdownOptions,"rules":"required"},model:{value:(_vm.regarding),callback:function ($$v) {_vm.regarding=$$v},expression:"regarding"}}),_vm._v(" "),_c('div',{staticClass:"sf-select__error-message"},[_c('span',[_vm._v(_vm._s(_vm.$t(errors[0])))])])],1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"message","rules":"required|max:800|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfTextarea',{staticClass:"form__element mt-4",attrs:{"label":_vm.$t('Comment'),"cols":"80","maxlength":"800","minlength":"10","wrap":"soft","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)}),_vm._v(" "),_c('SfCheckbox',{staticClass:"text-base font-normal",attrs:{"name":"callRequirement","label":_vm.$t('Do you want us to call you?'),"valid":""},model:{value:(_vm.callRequirement),callback:function ($$v) {_vm.callRequirement=$$v},expression:"callRequirement"}}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha',{on:{"success":_vm.onRecaptchaSuccess}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form__action flex"},[_c('BKButton',{staticClass:"w-min",attrs:{"type":"submit","loading":_vm.isLoading,"disabled":invalid || (_vm.isRecaptchaEnabled && !_vm.isRecaptchaSuccessful)},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.$t('Submit'))+"\n            ")]},proxy:true}],null,true)})],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }