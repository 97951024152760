














import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SfImage },
  setup() {
    return {
     addBasePath,
    };
  },
});
