




























import { defineComponent } from '@nuxtjs/composition-api';

import { PropType } from 'vue';
import { Image } from '~/cms/models/Image.model';
import { optimizeImage } from '~/helpers/image';

import BikeDeliveryForm from './BikeDeliveryForm.vue';
import BikeDeliveryResult from './BikeDeliveryResult.vue';
import { useBikeDeliveryApi } from './useBikeDeliveryApi';
import { EnumButtonVariant } from '../models/Button.model';

export default defineComponent({
  name: 'BikeDelivery',
  components: {
    BikeDeliveryResult,
    BikeDeliveryForm,
  },
  props: {
    backgroundImage: { type: Object as PropType<Image | null>, default: () => null },
  },
  setup(props) {
    const { getBikeDeliveryByClientId } = useBikeDeliveryApi();

    return {
      EnumButtonVariant,
      getBikeDeliveryByClientId,
      wrapperStyles: props.backgroundImage?.id
        ? { backgroundImage: `url(${optimizeImage(props.backgroundImage)})` }
        : {},
    };
  },
  data() {
    return {
      bikeDelivery: null,
      loading: false,
      validationError: null,
      error: null,
    };
  },
  mounted() {
    const clientIdFromUrl = this.$route.query.kunde;
    if (clientIdFromUrl && typeof clientIdFromUrl === 'string') {
      this.submit(clientIdFromUrl);
    }
  },
  methods: {
    async submit(clientId: string) {
      if (!clientId) {
        this.validationError = this.$t('Please tell us your customer number');
        return;
      }

      this.bikeDelivery = null;
      this.loading = true;
      this.validationError = null;

      try {
        const bikeDelivery = await this.getBikeDeliveryByClientId(clientId);

        if (!bikeDelivery) {
          this.validationError = this.$t(
            'Customer number not correct. Please check your input',
          );
          return;
        }
        this.bikeDelivery = bikeDelivery;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  },
});
