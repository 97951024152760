






















import { defineComponent, ref, Ref } from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';
import { isBreakpointSm } from './helpers/breakpoints';
import Teaser from './Teasers/components/Teaser.vue';

export default defineComponent({
  name: 'TeaserSliders',
  components: {
    SfCarousel,
    Teaser,
  },
  props: {
    headline: { type: String, default: '' },
    items: { type: Array, default: () => [] },
  },
  setup(props) {
    const carouselSettings: Object = {
        type: 'slider',
        startAt: 0,
        rewind: true,
        perView: 2,
        cloningRatio: 3,
        gap: '24px',
        peek: { before: 0, after: 100 },
    };

    const personalizedItems : Ref<Object[]> = ref(props.items);

    return {
      carouselSettings,
      personalizedItems,
    };
  },
  mounted() {
    this.personalizedItems = isBreakpointSm(window) ? [...this.items, ...this.items] : this.items;
  },
});
