/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 12, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations {
        label
        count
        attribute_code
        options {
          count
          label
          value
          __typename
        }
        position
        __typename
      }
      __typename
      items {
        __typename
        attribute_set_id
        uid
        sku
        name
        stock_status
        only_x_left_in_stock
        custom {
          show_as_new
          show_as_available_soon
          manufacturer_name
          manufacturer_goods
          groupset
          manufacturer_engine_name
        }
        ... on ConfigurableProduct {
          variants {
            product {
              uid
              id
              sku
              stock_status
              quantity
            }
            attributes {
              code
              label
              value_index
            }
          }
        }
        thumbnail {
          url
          position
          disabled
          label
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
