var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"flex flex-col md:flex-row gap-4"},[_c('ValidationProvider',{staticClass:"md:w-3/6",attrs:{"name":"firstName","rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"label":_vm.$t('First Name'),"name":"firstName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"md:w-3/6",attrs:{"rules":"required|max:40","name":"lastName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"label":_vm.$t('Last Name'),"name":"lastName","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col md:flex-row gap-4"},[_c('ValidationProvider',{staticClass:"md:w-3/6",attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"label":_vm.$t('Email address'),"name":"email","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.emailField),callback:function ($$v) {_vm.$set(_vm.form, "emailField", $$v)},expression:"form.emailField"}})]}}])}),_vm._v(" "),_c('ValidationProvider',{staticClass:"md:w-3/6",attrs:{"name":"tlf","rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"label":_vm.$t('Phone number'),"name":"tlf","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }