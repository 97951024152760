import { useApi } from '~/composables';
import { BikeDelivery, getBikeDeliveryByClientIdQuery } from './bikeDelivery.gql';

export function useBikeDeliveryApi() {
    const { query } = useApi();

    const getBikeDeliveryByClientId = async (clientId: string) => {
        const result = await query<{ bikeDelivery: BikeDelivery }>(getBikeDeliveryByClientIdQuery, { clientId });

        return result.data.bikeDelivery;
    };

    return { getBikeDeliveryByClientId };
}
