export default `
  query getCustomCustomer
    {
      customer {
        addresses {
          city
          country_code
          company
          default_billing
          default_shipping
          extension_attributes {
            attribute_code
            value
            __typename
          }
          firstname
          id
          lastname
          postcode
          prefix
          region {
            region
            region_code
            region_id
            __typename
          }
          street
          suffix
          telephone
          vat_id
          __typename
        }
        date_of_birth
        default_billing
        default_shipping
        email
        firstname
        is_subscribed
        lastname
        middlename
        prefix
        suffix
        taxvat
        gender
        __typename
        custom {
          bk_weight
          bk_height
          bk_step_length
          bk_show_matching_bikes
          bk_matching_bikes
          bk_matching_bikes_skus
        }
      }
    }
`;
