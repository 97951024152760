import { useContext } from '@nuxtjs/composition-api';

export function useRecaptcha() {
    const { $recaptcha, $config } = useContext() as any;
    const isRecaptchaEnabled = typeof $recaptcha !== 'undefined' && $config.isRecaptcha;

    const getRecaptchaToken = async () => {
        if (!isRecaptchaEnabled) return 'dev-token';

        try {
            $recaptcha.init();
            const response = await $recaptcha.getResponse();
            return response;
        } catch (e) {
            console.error('Recaptcha Error:', e);
        }

        return null;
    };

    return getRecaptchaToken;
}
