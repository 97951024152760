







































import { defineComponent } from '@nuxtjs/composition-api';
import { SfBadge, SfIcon, SfTopBar } from '@storefront-ui/vue';
import { arrowRightIcon } from '~/cms/helpers/customIcons';
import { openCmsLink } from '~/cms/helpers/urls';
import { EnumButtonVariant } from '~/cms/models/Button.model';
import { Link } from '~/cms/models/Link.model';

enum EnumBadgeColor {
  RED = 'red',
  GREEN = 'green',
}

export default defineComponent({
  name: 'TopBar',
  components: {
    SfTopBar,
    SfIcon,
    SfBadge,
  },
  props: {
    content: { type: Object, default: () => {} },
  },
  setup(props) {
    const { badgeColor } = props.content;
    const { badgeText } = props.content;
    const { text } = props.content;
    const { link } = props.content;
    const hasLinkUrl: boolean = Boolean(link && (link.url || link.cached_url || link.id));

    return {
      badgeColor,
      badgeText,
      text,
      link,
      EnumButtonVariant,
      EnumBadgeColor,
      arrowRightIcon,
      hasLinkUrl,
    };
  },
  methods: {
    openLink(link: Link): void {
      // Here we want to see if we receive a normal url or an internal one
      if (!this.hasLinkUrl) {
        return;
      }

      openCmsLink(link, this.$router);
    },
  },
});
