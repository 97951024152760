import { gql } from './utils';

export const calculateMatchingBikesMutation = gql(`
    mutation calculateMatchingBikes($input: BikeMatchInput!) {
        calculateMatchingBikes(input: $input) {
            data {
                matching_bikes_count_total
                matching_bikes_count_current_category
                category_name
                user_frametool {
                    gender
                    height
                    weight
                    step_length
                    current_category
                }
                bk_show_matching_bikes
                bk_matching_bikes {
                    sku
                    size_index
                    size_label
                    in_stock
                }
                bk_matching_bikes_skus {
                    id
                    skus
                }
            }
            errors {
                field
                message
            }
        }
    }
`);

export type BikeMatchInput = {
  category_id?: number | null;
  gender: string;
  height: number;
  weight: number;
  step_length: number;
};

export type BikeMatchResponse = {
  data: BikeMatchOutput | null;
  errors: {
    field: string;
    message: string;
  }[];
};

export type BikeMatchOutput = {
  matching_bikes_count_total: number;
  matching_bikes_count_current_category?: number | null;
  category_name?: string | null;
  user_frametool: {
    gender: string;
    height: number;
    weight: number;
    step_length: number;
    current_category?: number | null;
  };
  bk_show_matching_bikes: boolean;
  bk_matching_bikes: {
    sku: string;
    size_index: string;
    size_label: string;
    in_stock: boolean;
  }[];
  bk_matching_bikes_skus: {
    id: string;
    skus: string[];
  }[];
};
